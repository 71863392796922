import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Tab, Nav, Button, Card, Modal, OverlayTrigger, Tooltip, TabPane, Dropdown, NavDropdown, Table } from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import FooterTwo from '../../components/FooterTwo';
import { Styles } from './styles/course.js';
import { Styles1 } from './styles/reviewForm.js';
import service from '../../services/service';
import UserService from '../../services/UserService';
import ReplyForm, { Replyform } from './components/ReplyForm';
import UpdateReviewform from './components/UpdateReviewForm';
import { Styles2 } from '../courses/styles/courseAccordian.js';
import { useHistory } from 'react-router-dom';
import RenderOnAuthenticated from '../account/RenderOnAuthenticated';
import RenderOnAnonymous from '../account/RenderOnAnonymous';
import swal from 'sweetalert';
import { useSelector, useDispatch } from 'react-redux';
import Modal1 from "react-modal";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import DataTableAssessment from '../assessment/DataTableAssessment';
import DiscussionMain from '../discussion/DiscussionMain';
import { format, render, cancel, register } from 'timeago.js';
import axios from 'axios';
import Toast from 'react-bootstrap/Toast';
import { Spinner } from 'react-bootstrap';
import CourseFeedback from '../account/CourseFeedback';
import { useTranslation } from 'react-i18next'
import cookies from 'js-cookie'
import AssignmentMain from '../assignment/AssignmentMain';
import { FacebookIcon, FacebookShareButton, LinkedinShareButton, TwitterIcon, TwitterShareButton, LinkedinIcon, WhatsappIcon, WhatsappShareButton } from 'react-share';
import DiscussionMain1 from '../discussion/DiscussionMain1';
import Timer from 'react-compound-timer';
import learnerService from '../../services/learnerService';
import instructorService from '../../services/instructorService';
import Videojs from '../../pages/instructor/instCourses/video'
import ModalVideo from 'react-modal-video';
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';
import EnrolledAssignment from './enrolledAssignment/EnrolledAssignment'
import moment from 'moment';
import { colors } from "../../components/common/element/elements.js";
import Query from '../courses/Query/Query';
import fileDownload from 'js-file-download';
import CourseFeedbackList from '../account/CourseFeedbackList';
import CryptoJS from "crypto-js";
import { DMS_URL, COURSE_URL, CERTIFICATION, COURSE_CLUG, PAYMENT, USER_API, Frontend_UI } from "./../../services/service";
import Swal from 'sweetalert2'
import vcimage from "../../assets/images/VC.jpg";
import useNonce from '../../helper/Nonce.js';

const languages = [

    {
        code: 'en',
        name: 'English',
        country_code: 'gb',
    },

    
]

function CourseDetails(props) {

    useEffect(() => {
        UserService.generateToken();
    }, []);

    const fetchAndSetNonce = useNonce();

    let courseID = props.match.params.id;
    let tenantID = props.match.params.tid;

    const currentLanguageCode = cookies.get('i18next') || 'en'
    const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
    const { t } = useTranslation()
    useEffect(() => {
        document.body.dir = currentLanguage.dir || 'ltr'
        document.title = t('app_title')
    }, [currentLanguage, t])
    Modal1.setAppElement('*');
    const history = useHistory();

    let mainurl = `${Frontend_UI}/course-details/`;
    let url = mainurl + courseID + '/' + tenantID;

    const decipher = salt => {
        const textToChars = text => text.split('').map(c => c.charCodeAt(0));
        const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);
        return encoded => encoded.match(/.{1,2}/g)
            .map(hex => parseInt(hex, 32))
            .map(applySaltToChar)
            .map(charCode => String.fromCharCode(charCode))
            .join('');
    }

    const um_api = USER_API;
    // const dms_url = instructorService.DMS_URL;
    ////// 

    const [courseId, setDecodedCourseId] = useState(null)
    const [tenantId, setDecodedTenantId] = useState(null)

    const [isDecodeValid, setIsDecodeValid] = useState("NOTVALID")

    const decodeHandler = () => {

        const secretKey = "cdac@123"

        const encodedCourseId = props.match.params.id.replace(/-/g, "+").replace(/_/g, "/");
        const decodedCourseId = CryptoJS.AES.decrypt(
            encodedCourseId,
            secretKey
        ).toString(CryptoJS.enc.Utf8);

        const encodedTenantId = props.match.params.tid.replace(/-/g, "+").replace(/_/g, "/");
        const decodedTenantId = CryptoJS.AES.decrypt(
            encodedTenantId,
            secretKey
        ).toString(CryptoJS.enc.Utf8);

        const regex = /^[0-9]+$/

        const isValidCourseId = regex.test(decodedCourseId)
        const isValidTenantId = regex.test(decodedTenantId)

        if (isValidCourseId && isValidTenantId) {
            setIsDecodeValid("VALID")
            setDecodedCourseId(decodedCourseId)
            setDecodedTenantId(decodedTenantId)
        }
        else {
            setIsDecodeValid("NOTVALID")

            swal({
                title: t('something_went_wrong_try_later'),
                text: t('redirecting_to_course_list'),
                timer: 5000,
            }).then(() => {
                history.push(`${process.env.PUBLIC_URL + "/course-grid/"}`);
            })
        }

    }
    const [showContentDependOnDate, ContentDependOnDate] = useState(false);

    useEffect(() => {


        if (isDecodeValid !== "VALID") {
            decodeHandler()
        }

    }, [isDecodeValid])

    const initialStateId = {
        firstName: '',
        lastName: '',
        email: '',
        mobile: '',
        gender: '',
        dob: '',
        instituteName: '',
        eduQualification: '',
        address: '',
        city: '',
        pincode: '',
        countryId: '',
        stateId: '',
        districtId: '',
        updateBy: props.match.params.updateBy,
        learnerUsername: props.match.params.learnerUsername,
        firstNameError: '',
        lastNameError: '',
        genderError: '',
        dobError: '',
        mobileError: '',
        eduQualificationError: '',
        instituteNameError: '',
        addressError: '',
        cityError: '',
        pincodeError: '',
        countryIdError: '',
        stateIdError: '',
        districtIdError: '',
        facebookId: '',
        twitterId: '',
        linkedinId: '',
        youtubeId: '',
        skypeId: '',
        facebookIdError: '',
        twitterIdError: '',
        linkedinIdError: '',
        youtubeIdError: '',
        skypeIdError: ''
    };

    const paymentinitialualue = {
        merchant_param1: "",
        merchant_param2: "",
        merchant_param3: "",
        merchant_param4: "",
        merchant_param5: "",
        promo_code: "",
        merchant_param1err: "",
        merchant_param2err: "",
        merchant_param3err: "",
        merchant_param4err: "",
        merchant_param5err: "",
    }

    // const [toggle, setToggle] = useState(false);
    const [check, setCheck] = useState(0)
    const [courseValue, setCourseValue] = useState([]);
    const [visibility, setVisibility] = useState(true);
    const [reviewvisibility, setReviewVisibility] = useState(false);
    const [reviewvisibilitybfrlgin, setreviewvisibilitybfrlgin] = useState(false);
    const [getcourseStructure, setcourseStructure] = useState([]);
    const [getcourseStructure1, setcourseStructure1] = useState([])
    const [assessDetails, setAssessDetails] = useState([]);
    const [getCourseAnnouncement, setCourseAnnouncement] = useState([]);
    const [apiError, setApiError] = useState();
    const [msg, setmsg] = useState();
    const [msgshow, setMsgShow] = useState(true);
    const [getCertiStatus, setCertiStatus] = useState();
    const [getCertificateURl, setCertificateURL] = useState();
    const [getInstructor, setInstructor] = useState([]);
    const [getViewModalState, setViewModalState] = useState();
    const [getServerTime, setServerTime] = useState();
    const [number, setNumber] = useState();
    const [getInstCourseStatus, setInstCourseStatus] = useState();
    const [userCount, setUserCount] = useState();
    const [feeStatus, setFeesStatus] = useState(false);
    const [getUserDetails, setUserDetails] = useState(initialStateId);
    const [getRating, setRating] = useState([]);
    const [getPaymentInfo, setPaymentInfo] = useState(paymentinitialualue);

    const [userCourseScheduleData, setUserCourseScheduleData] = useState(null);

    useEffect(() => {

        if (isDecodeValid === "VALID") {

            courseMetaData();
            getUserTenantCourseDetails();
            callCourseDelievery();
            getCertificateSatus(courseId);
            if (userId) {
                getEnrollmentStatus();
            }
            if (UserService.isLoggedIn()) {
                getUserById();
                announcementCourse();
                getbyCourseIdandUserId();
                paymentDetailsStatus();
            }
            courseStructureById();
            serverTime();
            noOfUser();

            const accordionButton = document.querySelectorAll(".accordion-button");
            accordionButton.forEach(button => {
                button.addEventListener("click", () => {
                    button.classList.toggle("active");
                    const content = button.nextElementSibling;

                    if (button.classList.contains("active")) {
                        content.className = "accordion-content show";
                        content.style.maxHeight = content.scrollHeight + "px";
                    } else {
                        content.className = "accordion-content";
                        content.style.maxHeight = "0";
                    }
                });
            });
        }

    }, [isDecodeValid, courseId])

    useEffect(() => {
        if (UserService.isLoggedIn()) {
            let rating = [{
                itemId: courseId,
                tenantId: 1
            }]

            service.averageRating(rating)
                .then(res => {
                    setAvgRating(res.data);
                    
                }).catch(error => {
                }
                );
        }
    }, [getRating, courseId])

    const courseMetaData = () => {
        learnerService.getCourseMetadataById(courseId)
            .then(res => {

                setCourseValue(JSON.parse(res.data));
            }).catch(err => {
                swal({
                    title: t('service_maintainance_down_alert'),
                    text: t('redirect_course_list'),
                    timer: 5000,

                }).then(() => {
                    history.push(`${process.env.PUBLIC_URL + "/course-grid/"}`);
                })
                //setmsg(t('service_maintainance_down_alert'));                
            })
    }

    const getUserTenantCourseDetails = () => {
        learnerService.getUserTenantCourseDetails(courseId, tenantId)
            .then((res) => {
                setUserCourseScheduleData(res.data);
                if (res.data === "") {
                    setUserCourseScheduleData(null)
                }
            })
            .catch((err) => {
  
            })
    }

    const getEnrollmentStatus = () => {


        service.getUserEnrollmentStatus(courseId, userId, 1, tenantId)
            .then(res => {

                if (res.data.courseEnrolled === true) {
                    setVisibility(false);
                }
                setInstCourseStatus(res.data.instCourseStatus);
                
            })
            .catch(err => {

                
            })
    }

    const courseStructureById = () => {


        learnerService.getCourseStructureById(courseId)
            .then(res => {
                if (res.data == "Course Structure is yet to publish") {
                    swal(t('msg'), t('plz_wait'), "warning");
                }
                else {
                    setcourseStructure(res.data);
                    setcourseStructure1(res.data.nodes);
                  
                }

            }).catch(err => {
                setmsg(t('service_maintainance_down_alert'));
            })
    }

    const serverTime = () => {


        instructorService.getServerTime()
            .then(res => {
                let serTime = res.data;
                // setServerTime(res.data);
                setServerTime(serTime.replace(/\s/g, 'T'))
            })
    }

    const noOfUser = () => {


        service.userCount(courseId, tenantId)
            .then(res => {

                setUserCount(res.data.userCount);
            }).catch(error => {
            })
    }

    const announcementCourse = () => {


        service.courseAnnouncement(courseId, tenantId)
            .then(res => {

                setCourseAnnouncement(res.data);
            }).catch(error => {

                setmsg(t('service_maintainance_down_alert'))
            });
    }

    const getUserById = () => {


        service.getUserById(userId)
            .then(res => {

                setUserDetails(res.data);
            })
            .catch(err => {

            });
    }

    const paymentDetailsStatus = () => {


        service.getPaymentDetails(userId, courseId, 1)
            .then(res => {

                if (res.data.status === "paid") {
                    setFeesStatus(true);
                }
                else {
                    setFeesStatus(false);
                }
            })
            .catch(err => {

            });
    }





    const [roleId, setRoleId] = useState("");

    const getbyCourseIdandUserId = () => {


        if (userId !== " ") {
            service.getRoleIdbyCourseIdandUserId(courseId, userId)
                .then((res) => {
                   
                    if (res.status === 200) {
                        setRoleId(res.data);
                    }
                    else {
                    }
                })
                .catch((err) => {
                })
        }
    }


    let [certificateViewButton2, setCertificateViewButton2] = useState(true);
    let [certificateViewButton, setCertificateViewButton] = useState(false);
    let [statusCer, setStatusCer] = useState(false);

    

    const getQuizzesStatus = async (userId, courseId) => {
        await learnerService.getAssignedQuizzesStatus(userId, courseId)
            .then(res => {
                res.data.assignedQuizzes.map((data) => {

                    if (data.isPassed === true) {
                        setCertificateViewButton(true);
                        setStatusCer(true);
                       
                    }
                    else {
                        setCertificateViewButton2(false);
                    }
                   
                })

            })
            .catch(err => {
            })
    }

    useEffect(() => {

        if (isDecodeValid === "VALID") {

            if (UserService.isLoggedIn()) {
                getQuizzesStatus(userId, courseId);
            }
            handleContentDependOnDate();
            instructorData();
        }

    }, [isDecodeValid, statusCer, courseId, showContentDependOnDate])

    useEffect(() => {

        if (isDecodeValid === "VALID") {

            handleContentDependOnDate();
        }

    }, [courseId, showContentDependOnDate])



    // const convertDate = (dateFormat) => {
    //     let timestamp = Date.parse(dateFormat);
    //     let d = new Intl.DateTimeFormat('en-in', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }).format(timestamp);
    //     return d;
    // }

    const convertDate = (dateFormat) => {
        let timestamp = Date.parse(dateFormat);
        let date = new Date(timestamp);
        let d = date.toLocaleString('en-IN', { hour12: false, timeZone: 'IST' });
        // let d = new Intl.DateTimeFormat('en-in', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }).format(timestamp);
        return d;
    }

    const fee_validator = (fees) => {
        if (fees === 0) {
            return <p>{t('free')}</p>
        }
        else {
            return <p>&#8377;{fees}</p>
        }
    }

    const [CourseEnrollment, setCourseEnrollment] = useState({
        isLoading: false
    })

    const [getModalState2, setModalState2] = useState({
        show: false,
        path: "",
        id: ""
    })
    const [getModalState5, setModalState5] = useState({
        show: false,
        path: ""
    })

    const customStyles1 = {
        content: {
            position: 'fixed',
            left: 2,
            right: 2,
            top: 2,
            bottom: 2,
            background: 'none',
            backgroundColor: 'white',
            padding: '0px',
        },
    };


    const enrollCourseHandler = () => {
        setCourseEnrollment({ isLoading: true });
        const courseEndDate = courseValue.closingDate;
        let data = {
            "courseId": courseId,
            "tenantId": tenantId,
            "userId": userId,
 
        }
       
        if (service.postUserEnrollment(data)
            .then(res => {
                if (res.data == "Course enrollment date is already passed") {

                    swal(t('Enrollmanet Closed'), t('Enrollment date has passed'), "error");
                    setVisibility(false);
                    window.location.reload();
                    setCourseEnrollment({ isLoading: false });

                }
                else if (res.status == 200) {
                    swal(t('course_enrolled_successfully'), t('course_enrolled_successfully_alert'), "success");
                    setVisibility(false);
                    window.location.reload();
                    setCourseEnrollment({ isLoading: false });
                }
            })
            .catch(err => {
                setCourseEnrollment({ isLoading: false });
            })) {
        }
    }



    const redirecrToCourse = (id, tid) => {

        const secretKey = "cdac@123"

        const encodedCourseId = CryptoJS.AES.encrypt(
            `${id}`,
            secretKey
        ).toString();
        const safeEncodedCourseId = encodedCourseId
            .replace(/\+/g, "-")
            .replace(/\//g, "_")
            .replace(/=+$/, "");

        const encodedTenantId = CryptoJS.AES.encrypt(
            `${tid}`,
            secretKey
        ).toString();
        const safeEncodedTenantId = encodedTenantId
            .replace(/\+/g, "-")
            .replace(/\//g, "_")
            .replace(/=+$/, "");

        history.push(`${process.env.PUBLIC_URL + "/course-details/"}${safeEncodedCourseId}/${safeEncodedTenantId}`);

    }


    const paymentValidations = () => {

        let status = true;
        let merchant_param1err = "";
        let merchant_param2err = "";
        let merchant_param3err = "";
        let merchant_param4err = "";
        let merchant_param5err = "";

        if (getPaymentInfo.merchant_param1.match(/^[A-Za-z0-9 \s]{0,100}$/)) {
            
        } else {
            merchant_param1err = "Words and numbers are allowed(max 100 letters)";
            status = false;
        }

        if (getPaymentInfo.merchant_param2.match(/^[A-Za-z0-9 \s]{0,100}$/)) {
            // setPaymentInfo({ ...getPaymentInfo, merchant_param2err:""});
            
        } else {
            merchant_param2err = "Words and numbers are allowed(max 100 letters)";
            status = false;
        }

        if (getPaymentInfo.merchant_param3.match(/^[A-Za-z0-9 \s]{0,100}$/)) {
            setPaymentInfo({ ...getPaymentInfo, merchant_param3err: "" });
            
        } else {
            merchant_param3err = "Words and numbers are allowed(max 100 letters)";
            status = false;
        }

        if (getPaymentInfo.merchant_param4.match(/^[A-Za-z0-9 \s]{0,100}$/)) {
            setPaymentInfo({ ...getPaymentInfo, merchant_param4err: "" });
            
        } else {
            merchant_param4err = "Words and numbers are allowed(max 100 letters)";
            status = false;
        }

        if (getPaymentInfo.merchant_param5.match(/^[A-Za-z0-9 \s]{0,100}$/)) {
            setPaymentInfo({ ...getPaymentInfo, merchant_param5err: "" });
            
        } else {
            merchant_param5err = "Words and numbers are allowed(max 100 letters)";
            status = false;
        }

        if (status == false) {

            setPaymentInfo(prevInfo => ({
                ...prevInfo,
                merchant_param1err: merchant_param1err,
                merchant_param2err: merchant_param2err,
                merchant_param3err: merchant_param3err,
                merchant_param4err: merchant_param4err,
                merchant_param5err: merchant_param5err,
            }));

            return false;
        }
        else {
            return true;
        }
    }

    const paymentForCourse = async (courseId, tenantId) => {

        let status = paymentValidations();

        let data = {
            courseId: courseId,
            userId: userId,
            tenantId: tenantId,
            merchant_param1: getPaymentInfo.merchant_param1,
            merchant_param2: getPaymentInfo.merchant_param2,
            merchant_param3: getPaymentInfo.merchant_param3,
            merchant_param4: getPaymentInfo.merchant_param4,
            merchant_param5: getPaymentInfo.merchant_param5,
            promo_code: getPaymentInfo.promo_code
        };



        if (status == true) {

            await service
                .paymentDetailSave(data)
                .then((res) => {
                    
                   
                    const TokenJsonString = JSON.stringify(res.data);
                    //  let encoded = encodeURIComponent(res.data);
                    // //setOrderId(res.data.orderId)

                    const form = document.createElement("form");
                    form.method = "post";
                    form.action = "https://test.ccavenue.com/transaction/transaction.do?command=initiateTransaction";
                    //  form.action = `https://apitest.ccavenue.com/apis/servlet/DoWebTrans?enc_request=${res.data.encRequest}&access_code=${res.data.access_code}&request_type=JSON&response_type=JSON&command=orderStatusTracker&version=1.2`;
                    //  form.action = `https://apitest.ccavenue.com/apis/servlet/DoWebTrans?enc_request=${res.data.encRequest}&access_code=${res.data.access_code}&request_type=XML&response_type=XML&command=confirmOrder&version=1.1`;


                    const hiddenField = document.createElement("input");
                    hiddenField.type = "hidden";
                    hiddenField.name = "encRequest";
                    hiddenField.id = "encRequest";
                    hiddenField.value = res.data.encRequest;


                    const hiddenField1 = document.createElement("input");
                    hiddenField1.type = "hidden";
                    hiddenField1.name = "access_code";
                    hiddenField1.id = "access_code";
                    hiddenField1.value = res.data.access_code;

                    //hiddenField.value = "{ clientId : cdac.hyd.chariot, requestToken : eyJlbmMiOiJBMTI4Q0JDLUhTMjU2IiwiYWxnIjoiZGlyIn0..4il_-eohyxyjSmXQy0ygBw.GRZZ5I7we8wwEB9eXEbxBZP6EPjhf3V5PY2x92bufA0-jEZtf-R8HHIUDoFz4qXFTRPKsNMzVXAZwU_EJEBJ_fYfPAjCibnZRZQHGT_995QBPVrCUmGcOIXf79C7bpUmIh4hDFXRFXvLKenBLJbicE-1Fae675oThqiYZZ2pzdPsAcaPrwZPsiYTvnpIVyp3saO0h3kk3bhsPo_pQF_8t9Ok3HSBqv-fs3LELEbsrqAQE_Z_ms4YEi0fRG6uW6M3Jr--s2kGuZF1Zsi-4nNcsht_NvylhqwIV6ObPJqinHjiqpYrv0wEheQpsG-zkf04lcyTUpMHLRWZpk4uVOvHdaWNdI8uuTm0_0W4TOZPOMrrKLszDgcOLU64mJedd6i-kOXIL2Kw9oOyX9eh1xQXnpbdR5owwh-Yd4COo1xKJOTgNiDUPoYDlgMhfBkIp1zwgXtQo66i6dBeaFo6XkTzwrXlenRwW1N9UMVjQqpjHVZniS_3CBfWgdN64TsJDo5JeFOc1icD00bWFulw9ir6YqV9CWcFi8_Jiu51PTRsZ14.-qcJIb3Zpe2irwqwcln5pg}";
                    // hiddenField.value = "{ clientId : cdac.hyd.chariot, requestToken : eyJlbmMiOiJBMTI4Q0JDLUhTMjU2IiwiYWxnIjoiZGlyIn0..KUkdrntsDut5As8X6qkPEg.IyVfYSS3X2w5ybBpSyICSUnXczfRwPdkiNFvGPIySGLVhw_yWEnlL_ei2KyfsnkOeIdI-8kkEpQ0yPtu0iWQ1QeqeegR_boGBNnT9I_xM2zUyg5ICjxefaf0ZPfCyCygQmZGGxuiCEzIgNRsfSRr8Onyu5EGemFuQb2QzeRg-ZBipi-VRlk__cBIwchy2OW3JjGmf4AkplroOiYqJVtzEOzx0fQVLSrupfCHO5vMmCP2QdFZLc5jcJ1AuNSRXcAgCnVJlm2afw4nYlzwdLT5Ce1chS6qQBMLpOxUMs7qtKEVw5KpFbras9mPM4AQZEwpZxY_9C7BV9zY-VqP0UV67EXkedskftfUUXH_CIDgq1jKvKgebAEwB2_wt1_kkQ0tlu0WO4hGx27TszGlgMUHYL7xQDv_89ByxMXlJcxcUhfxcgOjhDTcA1_23tsLDjH1ZIy9JnQGhIoU48z1H4OWL5ArfRx21lKheo4UB94nkCXnkMvGF6NaUkRtTFJKFi22RvKpZ_M5hkMvzCImEw9xH2i8NAl-VE3le4RwFker17A.I7P1Yh79WgOout1tjd3GgQ}";

                    form.appendChild(hiddenField);
                    form.appendChild(hiddenField1);
                    document.body.appendChild(form);
                    form.submit();
                })
                .catch((err) => {
                });

        }

    };

    const [getModalState, setModalState] = useState({
        show: false
    })


    const [getModalState1, setModalState1] = useState({
        show: false
    })

    const [getcourseId, setCourseId] = useState(0);

    const [getreviewText, setReviewText] = useState(0)
    const [getreviewId, setreviewId] = useState(0)
    const [getureviewId, setureviewId] = useState(0)

    const [getstarrate, setstarrate] = useState(0)
    let userId = UserService.getUserid();
    let username = UserService.getUsername();
    let userEmail = UserService.getEmail();
    let id = 12;

    let reviewId = { getreviewId }





    const state = {
        rating: '',
        reviewText: '',
        learnerId: userId,
        itemId: courseId,
        reviewStatus: 'Done',
        reviewType: 1,
        ratingError: '',
        reviewTextError: '',
        tenantId: tenantId,

    }

    const [getRate, setRate] = useState(state);



    const validate = () => {
        let ratingError = '';
        let reviewTextError = '';
        if (!getRate.rating) {
            ratingError = t('rating_cannot_be_blank');
        }
        if (ratingError != '') {
            setRate({ ...getRate, ratingError });
            return false;
        }
        if (!getRate.reviewText) {
            reviewTextError = t('comment_cant_be_blank');
        }
        if (reviewTextError != '') {
            setRate({ ...getRate, reviewTextError });
            return false;
        }
        return true;
    }

    const [loading, setLoading] = useState();
    const saverating = async () => {
        await fetchAndSetNonce("Review");
        if (validate()) {
            setLoading(true);
            let review = { rating: getRate.rating, reviewText: getRate.reviewText, learnerId: getRate.learnerId, itemId: courseId, reviewStatus: getRate.reviewStatus, reviewType: getRate.reviewType, tenantId: 1, reviewId: 0 };
            service.createrating(review).then(async response => {
                setLoading(false);
                await swal(t('review_submited_succesfully'), t('review_submited_succesfully_alert'), "success");
                getOverAllRating();
            }).catch(err => {
                setLoading(false);

            });
        }
    }


    useEffect(() => {
        const courseButton = document.querySelectorAll(".course-button");
        courseButton.forEach(button => {
            button.addEventListener("click", () => {
                button.classList.toggle("active");
                const content = button.nextElementSibling;

                if (button.classList.contains("active")) {
                    content.className = "course-content show";
                    content.style.maxHeight = content.scrollHeight + "px";
                } else {
                    content.className = "course-content";
                    content.style.maxHeight = "0";
                }
            });
        });

        getOverAllRating();

        if (UserService.isLoggedIn()) {
            getOverAllRating();
        }

    }, [courseId]);

    const [reviewTextBoxCondition, setReviewTextBoxCondition] = useState(true);

    const getOverAllRating = () => {
        if(courseId != null){
        service.getoverallRating(courseId, tenantId)
            .then(res => {
                res.data.map((t) => {
                   
                    if (t.learnerUsername === UserService.getUserid()) {
                        setReviewTextBoxCondition(false);
                    }
                })
                setRating(res.data.filter(function (ele) {
                    return ele.tenantId == tenantId
                }))
                _checkthis(res.data);
            })
            .catch(err => {

                setReviewTextBoxCondition(true);
            })
        }
    }

    function _checkthis(_thisrating) {
        {
            _thisrating.map((item) => {
                if (item.learnerId == userId) {
                    setReviewVisibility(true);
                }
                if (userId == undefined) {
                    setreviewvisibilitybfrlgin(true);
                }
            })
        }
    }
    const handleModal = (rrId) => {
        setModalState({ show: true })
        setreviewId(rrId);
        //alert("reviewID:::::" + rrId);
    }

    const handleModal2 = () => {
        setModalState({ show: false })
    }

    const handleModal3 = () => {
        setModalState1({ show: false })
    }
    const handleModal4 = () => {
        setModalState2({ show: false })
    }

    const handleModal5 = () => {
        setModalState5({ show: false })
    }



    const UpdateReview = (reviewText, starrating, urid, userId) => {
        setModalState1({ show: true })
        setReviewText(reviewText);
        setstarrate(starrating);
        setCourseId(courseId);
        setureviewId(urid);

    }

    const refreshPage = () => {
        //alert("check");
        window.location.reload();
    }



    const DeleteReview = (reviewId) => {
        //setModalState5({ show: true })
        service.deleterating(reviewId)
            .then(async res => {
                await swal(t('review_deleted_successfully'), t('you_can_check_in_the_reviews'), "success");
                refreshPage();
            })
            .catch(err => {

            })
        //swal("Review deleted  Successfully!", "You can check in the  Reviews!", "success");
    }
    //end of review code


    const [show, setShow] = useState(false)

    //scrolling 
    const scrollWin = () => {
        document.getElementById('Main').scrollIntoView({ behavior: 'smooth' })
        //window.scrollTo(0, 290);
    }

    const [getAvgRating, setAvgRating] = useState([]);

    const dateConverter = (dateFormat) => {
        let timeAgo = format(dateFormat, 'en_US');
        return timeAgo;
    }

    const [getAnnouncmentModal, setAnnouncementModal] = useState(false);
    const [announcementData, setAnnouncementData] = useState({
        title: '',
        body: '',
        date: ''
    })
    const AnnouncementModal = (title, body, date) => {
        setAnnouncementData({ title: title, body: body, date: date })
        setAnnouncementModal(true);
    }
    const openFeedback = (typeid, cid) => {
        history.push(`${process.env.PUBLIC_URL + "/feedback/"}${typeid}/${cid}`);
    }

    const certificateDownload = () => {
        axios({
            method: 'post',
            url: `${COURSE_CLUG}/CourseCatalouge/certificate?tenantId=${tenantId}&courseId=${courseId}&emailId=${userId}&name=${username}`,
            responseType: 'blob'
        })
            .then((res) => {
                const file = new Blob(
                    [res.data],
                    { type: 'application/pdf' });

                const url = URL.createObjectURL(file);
                const a = document.createElement('a');
                a.href = url;
                a.download = `${courseValue.courseName} Certificate.pdf`;
                a.click();
            })
    }

    const certificateView = () => {
        setViewModalState(true);
        axios({
            method: 'post',
            url: `${COURSE_CLUG}/CourseCatalouge/certificate?tenantId=${tenantId}&courseId=${courseId}&emailId=${userId}&name=${username}`,
            responseType: 'blob'
        })
            .then((res) => {
                const file = new Blob(
                    [res.data],
                    { type: 'application/pdf' });
                const fileURL = URL.createObjectURL(file);
                setCertificateURL(fileURL);
            })
    }

    const [getInstructorImg, setInstructorImg] = useState({
        img: um_api + "getprofilepic/"
    });

    const instructorData = () => {
        service.getInstructorDetails(courseId, tenantId)
            .then((res) => {
                if(res.data){

                    setInstructor(res.data);
                }
            })
            .catch(err => {

            })
    }

    const [getUrlModal, setUrlModal] = useState({
        show: false
    })
    const UrlModalHide = () => {
        setUrlModal({ show: false })
    }

    const [getPaymentParameterView, setPaymentParameterView] = useState({
        show: false
    });

    const PaymentParameterView = () => {
        setPaymentParameterView({ show: true })
    }

    const [getFileURL, setFileURL] = useState(null);

    const certificateDownloadFunction = (userId, courseId, tenantId) => {

        setFileURL(null);

        learnerService.toGenerateCertificate(userId, courseId, tenantId)
            .then(res => {

                if (res.status == '200' && res.data == "registrationId-is-null") {
                
                    swal(`${t('error')}`, "Kindly update your registration Id in Profile.", "error")

                }

                else if (res.status == '200' && res.data == "Course-not-completed") {
                
                    swal(`${t('error')}`, "Kindly complete the course content.", "error")

                }

                else if (res.status == '200') {
                
                    learnerService.toDownloadCertificate(courseId, res.data)
                        .then(res => {

                            fileDownload(res.data, "Certificate.pdf");
                        })
                        .catch(err => {

                        })

                }

            }).catch(err => {
                swal(t('error_mesg'), t('try_sometimes'), "error");
            })

    }

    // Certificate Modal show and hide
    const handleCertificateView = () => {
        let userId = UserService.getUserid();
        let tenantId = 1;
        certificateDownloadFunction(userId, courseId, tenantId);
        //setCertificateView({ show: true })
    }

    const PaymentParameterViewHide = () => {
        setPaymentParameterView({ show: false })
    }

    const [getUrl, setUrl] = useState();
    const [getContentType, setContentType] = useState();
    const courseStructurContentView = (contentType, fileUrl) => {
        if (contentType == "youtube") {
            setUrl(fileUrl);
            setContentType(contentType);
            setUrlModal({ show: true });
        } else {
            instructorService.contentAccess(DMS_URL + "/" + fileUrl)
                .then(res => {
                    setUrl(res.data);
                    setContentType(contentType);
                    setUrlModal({ show: true });
                    //window.open(DMS_URL+"/" + res.data, "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,width=1000,height=1000");
                }).catch(err => {
                    swal(t('error_mesg'), t('try_sometimes'), "error");
                })
        }
    }

    const videoJsOptions = {
        autoplay: false,
        playbackRates: [0.5, 1, 1.25, 1.5, 2],
        width: 1100,
        height: 800,
        controls: true,
        sources: [
            {
                src: `${DMS_URL}/${getUrl}`,
                type: 'video/mp4',
            },
        ]
    };

    const Tree = ({ data }) => (
        <Styles>
            <div className="course-content show">
                <ul class="tree1 list-unstyled">
                    {data && data.map(item => (
                        (visibility || roleId !== 1) ? <li style={{ fontSize: "16px" }}>
                            {item.nodetype == "folder" ?
                                <p style={{ borderBottom: '1px solid #dddddd' }}><span className="play-icon"><i className="las la-book-open"></i>{item.label}&nbsp;&nbsp;</span></p>
                                : null
                            }
                            {item.nodes &&
                                <ul>
                                    {<Tree data={item.nodes} />}
                                </ul>
                            }
                        </li> :

                            <li style={{ fontSize: "16px" }}>
                                {item.nodetype == "folder" ?
                                    <p style={{ borderBottom: '1px solid #dddddd' }}><span className="play-icon"><i className="las la-book-open"></i>{item.label}&nbsp;&nbsp;</span></p>
                                    : item.nodetype == "html" ? < span className="play-icon"><i className="lab la-html5" style={{ fontSize: "25px", color: "#e54c21" }}></i> <a href='#' onClick={() => courseStructurContentView(item.nodetype, item.filePath)}>{item.label}&nbsp;&nbsp; </a> <span className="lecture-duration">{item.duration == undefined ? null : <p>{t('duration')} {item.duration}</p>}</span></span>
                                        : item.nodetype == "png" || item.nodetype == "jpg" ? < span className="play-icon"><i className="las la-image" style={{ fontSize: "25px", color: "#b2b1ff" }}></i> <a href='#' onClick={() => courseStructurContentView(item.nodetype, item.filePath)}>{item.label}&nbsp;&nbsp; </a><span className="lecture-duration">{item.duration == undefined ? null : <p>{t('duration')} {item.duration}</p>}</span></span>
                                            : item.nodetype == "pdf" ? < span className="play-icon"><i className="las la-file-pdf" style={{ fontSize: "25px", color: "#b30b00" }}></i> <a href='#' onClick={() => courseStructurContentView(item.nodetype, item.filePath)}>{item.label}&nbsp;&nbsp; </a><span className="lecture-duration">{item.duration == undefined ? null : <p>{t('duration')} {item.duration}</p>}</span></span>
                                                : item.nodetype == "mp4" ? < span className="play-icon"><i className="las la-video" style={{ fontSize: "25px", color: "#8cee02" }}></i> <a href='#' onClick={() => courseStructurContentView(item.nodetype, item.filePath)}>{item.label}&nbsp;&nbsp; </a><span className="lecture-duration">{item.duration == undefined ? null : <p>{t('duration')} {item.duration}</p>}</span></span>
                                                    : item.nodetype == "zip" ? < span className="play-icon"><i className="las la-file-archive" style={{ fontSize: "25px", color: "#fdbf00" }}></i> <a href='#' onClick={() => courseStructurContentView(item.nodetype, item.filePath)}>{item.label}&nbsp;&nbsp; </a><span className="lecture-duration">{item.duration == undefined ? null : <p>{t('duration')} {item.duration}</p>}</span></span>
                                                        : item.nodetype == "scorm" ? < span className="play-icon"><i className="las la-file-archive" style={{ fontSize: "25px", color: "#665245" }}></i> <a href='#' onClick={() => courseStructurContentView(item.nodetype, item.filePath)}>{item.label}&nbsp;&nbsp; </a><span className="lecture-duration">{item.duration == undefined ? null : <p>{t('duration')} {item.duration}</p>}</span></span>
                                                            : <span>{item.label}</span>
                                }
                                {item.nodes &&
                                    <ul>
                                        {<Tree data={item.nodes} />}
                                    </ul>
                                }
                            </li>
                    ))}
                </ul>

            </div>
        </Styles >
    );

    let sTime = getServerTime;
    let cTime = courseValue.closingDate;
    let d1 = new Date(sTime);
    let d2 = new Date(cTime);
    let diffTime = Math.abs(d2.getTime() - d1.getTime());
    let n = new Number(diffTime)
    let arrrr = [
        {
            time: 123132132
        }
    ]


    const toggleHandler = (key) => {
        const updatedCourseStructure = getcourseStructure1.map((singleData, index) => {
            if (singleData.key === key) {
                return { ...singleData, isActive: !(singleData.isActive) }
            }
            return singleData;
        })

        setcourseStructure1(updatedCourseStructure)
    }

    function sumOfAllContentDuration(data) {
        if (data.length === 0) {
            data = "";
        } else {
            var res = data && data.map(d => d.duration).reduce((a, b) => b + a);
            return res;
        }
    }

    function dateCompareFunction(sysDate, enrolmentSDate) {
       
        // const enrolmentSDate1 = moment(enrolmentSDate).format('DD/MM/yyyy');
        // const sysDate1 = moment(sysDate).format('DD/MM/yyyy');

        var sysDate1 = new Date(sysDate);
        var enrolmentSDate1 = new Date(enrolmentSDate);


       
        const x = new Date(sysDate1);
        const y = new Date(enrolmentSDate1);
       
        return (x >= y);
    }



    function getVideoId(url) {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        const match = url?.match(regExp);

        return (match && match[2].length === 11)
            ? match[2]
            : null;
    }

    const [fullscreen, setFullscreen] = useState(true);
    const [show2, setShow2] = useState(false);

    function handleShow() {
        setShow2(true);
    }

    const cipher = salt => {
        const textToChars = text => text.split('').map(c => c.charCodeAt(0));
        const byteHex = n => ("0" + Number(n).toString(32)).substr(-2);
        const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);
        return text => text.split('')
            .map(textToChars)
            .map(applySaltToChar)
            .map(byteHex)
            .join('');
    }






    const callCourseDelievery = () => {

        const condition = handleContentDependOnDate();
        if (condition === true && visibility !== true && roleId === 1 && (courseValue.courseType == "free" || feeStatus === true)) {

            CourseDelivery(courseValue.courseId, 1);
        }
    }

    const CourseDelivery = (id, tid) => {


        const secretKey = "cdac@123"

        const encodedCourseId = CryptoJS.AES.encrypt(
            `${id}`,
            secretKey
        ).toString();
        const safeEncodedCourseId = encodedCourseId
            .replace(/\+/g, "-")
            .replace(/\//g, "_")
            .replace(/=+$/, "");

        const encodedTenantId = CryptoJS.AES.encrypt(
            `${tid}`,
            secretKey
        ).toString();
        const safeEncodedTenantId = encodedTenantId
            .replace(/\+/g, "-")
            .replace(/\//g, "_")
            .replace(/=+$/, "");
        history.push(`${process.env.PUBLIC_URL + "/ContentDelivery/"}${safeEncodedCourseId}/${safeEncodedTenantId}`);
    }

  

    // data filter depend on end date
    const dateLimit = moment(userCourseScheduleData === null ? courseValue.closingDate : userCourseScheduleData.courseEndDate, 'YYYY-MM-DDTHH:mm:ss.SSSZ');
    const now = moment();
    const handleContentDependOnDate = () => {
        const dateLimit = moment(userCourseScheduleData === null ? courseValue.closingDate : userCourseScheduleData.courseEndDate, 'YYYY-MM-DDTHH:mm:ss.SSSZ');
        const now = moment();
       
        //let dateNew = new Date();
        if (dateLimit.isValid() && dateLimit.isAfter(now)) {
            // ContentDependOnDate(true);
            return true
            showContentDependOnDate = true;
        } // show content if current date is less than end date
    };

    



    function changeBackgroundOver(e) {
        e.target.style.background = 'linear-gradient(90deg, #003994 0%, #007bc7 100%)';
        e.target.style.border = '0px';
    }

    function changeBackgroundOut(e) {
        e.target.style.background = 'linear-gradient(90deg, #007bc7 0%, #003994 100%)';
        e.target.style.border = '0px';
    }

    const [downloadCertificateicon, setDownloadCertificateicon] = useState(false);

    function changeBackgroundOverCertiButton(e) {
        //e.target.style.background = 'linear-gradient(90deg, #003994 0%, #007bc7 100%)';
        e.target.style.border = '0px';
        setDownloadCertificateicon(true);
    }

    function changeBackgroundOutCertiButton(e) {
        e.target.style.background = 'linear-gradient(90deg, #007bc7 0%, #003994 100%)';
        e.target.style.border = '0px';
        setDownloadCertificateicon(false);
    }


    const scrollToView = () => {
        const ele = document.getElementById('Tab');
        ele.scrollIntoView({ behavior: 'smooth' })
        //ele.scrollIntoView({behavior : 'smooth'})
        
    }

   

    const initialActivityStatus = {
        activityId: "",
        activityProgress: 0,
        activityProgressforDependency: 0,
        dependency: 0,
        status: "Not Completed"
    };
    const [getActivitiesStatus, setActivitiesStatus] = useState(initialActivityStatus);

    const fetchActivitiesStatus = () => {
        if (isDecodeValid === "VALID" && userId != null) {
            service.courseActivitiesStatus(userId, courseId)
                .then(res => {

                    setActivitiesStatus({ ...res.data });
                })
                .catch(err => {
                    // Handle errors
                    // console.error(err);
                });
        }
    };

    useEffect(() => {

        fetchActivitiesStatus();

    }, [courseId]);


    const CourseCompletionStatus = (getActivitiesStatus1) => {

        if (isDecodeValid === "VALID") {
            const areAllTasksCompleted = Object.values(getActivitiesStatus1).every(getActivitiesStatus1 => getActivitiesStatus1.status === "Completed" || getActivitiesStatus1.activityProgress == 100);
            return areAllTasksCompleted;
        }
    }

    const LoginMUHS = async () => {
        try {
            const { value: user } = await Swal.fire({
                title: "Select your user type",
                icon: "question",
                input: "select",
                inputOptions: {
                    muhs: "MUHS User",
                    nonmuhs: "Non MUHS Users"
                },
                inputPlaceholder: "Select your user type",
                showCloseButton: true,
                showCancelButton: true,

            });
            if (user == "muhs") {
                Swal.fire(`We will update you soon.`);
            }
            else if (user == "nonmuhs") {
                UserService.doLogin();
            }
            else if (user == "") {
                const result = await Swal.fire({
                    title: 'Please select your user type.',
                    showConfirmButton: true
                })
                if (result.isConfirmed) {
                    LoginMUHS();
                }
            }
            else {

            }
        } catch (error) {
            console.error('Error occurred:', error);
        }
    };

    const [getCrtStatus, seCrtStatus] = useState();

    const getCertificateSatus = (course) => {
        instructorService.getCertificateStatus(course)
            .then((res) => {
                
                seCrtStatus(res.data);
            }).catch((err) => {
            })

    }


    return (
        <div className="main-wrapper course-details-page" onLoad={() => scrollWin()}>
            {
            }

            {/* Header 2 */}
            < HeaderTwo />

            {/* Breadcroumb */}

            {/* < BreadcrumbBox title="Course Details" bannerUrl={`${COURSE_URL}/${courseValue.banner}`} /> */}

            < BreadcrumbBox title={courseValue.categoryName} />

            <Styles>
                {/* Course Details */}
                {msg == null ? null :
                    <Toast show={msgshow} style={{ right: 0, backgroundColor: '#17a2b8', color: 'white', width: '300px' }} className="position-absolute top-0 end-0 m-4" onClose={() => setMsgShow(false)} delay={5000} autohide>
                        <Toast.Header style={{ fontSize: '15px' }}>
                            <i class="las la-info-circle"></i>
                            <strong className="mr-auto">{t('info')}</strong>
                            <small>{t('just_now')}</small>
                        </Toast.Header>
                        <Toast.Body >
                            {msg}
                        </Toast.Body>
                    </Toast>
                }
                <section className="course-details-area" id='Main'>
                    <Container fluid>
                        <Row>
                            {/* <Col lg="8" md="7" sm="12"> */}
                            <div className="course-details-top">
                                <Col lg={{ span: 6, offset: 1 }}>
                                    <div className="heading">
                                        <h4 dangerouslySetInnerHTML={{ __html: courseValue.courseName }}></h4>
                                        {/* <p dangerouslySetInnerHTML={{ __html: getCourseDetails.generalDetails }}></p> */}
                                    </div>

                                    <div className="course-top-overview">
                                        <div className="d-flex overviews">
                                            {/* <div className="author">
                                                <img src={process.env.PUBLIC_URL + `/assets/images/author.jpg`} alt="" />
                                                <i className="las la-chalkboard-teacher" style={{ fontSize: "32px", marginLeft: "10px" }}></i>
                                                <div className="author-name">
                                                    <h6>{t('author')}</h6>
                                                    <p>-</p>
                                                </div>
                                            </div> */}


                                            <div className="category">
                                                <h6>{t('category')}</h6>
                                                <p dangerouslySetInnerHTML={{ __html: courseValue.categoryName }}></p>
                                            </div>
                                            <div className="rating">
                                                <h6>{t('rating')}</h6>
                                                <ul className="list-unstyled list-inline">
                                                    {
                                                        getAvgRating.map((d) => {
                                                            return (
                                                                <>
                                                                    {
                                                                        tenantId == d.tenantId && courseId == d.itemId ? d.avgScore == 1 ?
                                                                            <>
                                                                                <li className="list-inline-item" style={{ color: "#be5a0e", fontWeight: "bold" }}>({tenantId == d.tenantId && courseId == d.itemId ? d.avgScore : null})&nbsp;</li>
                                                                                <li className="list-inline-item"><i className="las la-star"></i></li>
                                                                            </>
                                                                            : tenantId == d.tenantId && courseId == d.itemId ? d.avgScore == 1.1 || d.avgScore == 1.2 || d.avgScore == 1.3 || d.avgScore == 1.4 || d.avgScore == 1.5 || d.avgScore == 1.6 || d.avgScore == 1.7 || d.avgScore == 1.8 || d.avgScore == 1.9 ?
                                                                                <>
                                                                                    <li className="list-inline-item" style={{ color: "#be5a0e", fontWeight: "bold" }}>({tenantId == d.tenantId && courseId == d.itemId ? d.avgScore : null})&nbsp;</li>
                                                                                    <li className="list-inline-item"><i className="las la-star"></i></li>
                                                                                    <li className="list-inline-item"><i className="las la-star-half-alt"></i></li>
                                                                                </>

                                                                                : tenantId == d.tenantId && courseId == d.itemId ? d.avgScore == 2 ?
                                                                                    <>
                                                                                        <li className="list-inline-item" style={{ color: "#be5a0e", fontWeight: "bold" }}>({tenantId == d.tenantId && courseId == d.itemId ? d.avgScore : null})&nbsp;</li>
                                                                                        <li className="list-inline-item"><i className="las la-star"></i></li>
                                                                                        <li className="list-inline-item"><i className="las la-star"></i></li>
                                                                                    </>

                                                                                    : tenantId == d.tenantId && courseId == d.itemId ? d.avgScore == 2.1 || d.avgScore == 2.2 || d.avgScore == 2.3 || d.avgScore == 2.4 || d.avgScore == 2.5 || d.avgScore == 2.6 || d.avgScore == 2.7 || d.avgScore == 2.8 || d.avgScore == 2.9 ?
                                                                                        <>
                                                                                            <li className="list-inline-item" style={{ color: "#be5a0e", fontWeight: "bold" }}>({tenantId == d.tenantId && courseId == d.itemId ? d.avgScore : null})&nbsp;</li>
                                                                                            <li className="list-inline-item"><i className="las la-star"></i></li>
                                                                                            <li className="list-inline-item"><i className="las la-star"></i></li>
                                                                                            <li className="list-inline-item"><i className="las la-star-half-alt"></i></li>
                                                                                        </>
                                                                                        : tenantId == d.tenantId && courseId == d.itemId ? d.avgScore == 3 ?
                                                                                            <>
                                                                                                <li className="list-inline-item" style={{ color: "#be5a0e", fontWeight: "bold" }}>({tenantId == d.tenantId && courseId == d.itemId ? d.avgScore : null})&nbsp;</li>
                                                                                                <li className="list-inline-item"><i className="las la-star"></i></li>
                                                                                                <li className="list-inline-item"><i className="las la-star"></i></li>
                                                                                                <li className="list-inline-item"><i className="las la-star"></i></li>
                                                                                            </>
                                                                                            : tenantId == d.tenantId && courseId == d.itemId ? d.avgScore == 3.1 || d.avgScore == 3.2 || d.avgScore == 3.3 || d.avgScore == 3.4 || d.avgScore == 3.5 || d.avgScore == 3.6 || d.avgScore == 3.7 || d.avgScore == 3.8 || d.avgScore == 3.9 ?
                                                                                                <>
                                                                                                    <li className="list-inline-item" style={{ color: "#be5a0e", fontWeight: "bold" }}>({tenantId == d.tenantId && courseId == d.itemId ? d.avgScore : null})&nbsp;</li>
                                                                                                    <li className="list-inline-item"><i className="las la-star"></i></li>
                                                                                                    <li className="list-inline-item"><i className="las la-star"></i></li>
                                                                                                    <li className="list-inline-item"><i className="las la-star"></i></li>
                                                                                                    <li className="list-inline-item"><i className="las la-star-half-alt"></i></li>
                                                                                                </>
                                                                                                : tenantId == d.tenantId && courseId == d.itemId ? d.avgScore == 4 ?
                                                                                                    <>
                                                                                                        <li className="list-inline-item" style={{ color: "#be5a0e", fontWeight: "bold" }}>({tenantId == d.tenantId && courseId == d.itemId ? d.avgScore : null})&nbsp;</li>
                                                                                                        <li className="list-inline-item"><i className="las la-star"></i></li>
                                                                                                        <li className="list-inline-item"><i className="las la-star"></i></li>
                                                                                                        <li className="list-inline-item"><i className="las la-star"></i></li>
                                                                                                        <li className="list-inline-item"><i className="las la-star"></i></li>
                                                                                                    </>
                                                                                                    : tenantId == d.tenantId && courseId == d.itemId ? d.avgScore == 4.1 || d.avgScore == 4.2 || d.avgScore == 4.3 || d.avgScore == 4.4 || d.avgScore == 4.5 || d.avgScore == 4.6 || d.avgScore == 4.7 || d.avgScore == 4.8 || d.avgScore == 4.9 ?
                                                                                                        <>
                                                                                                            <li className="list-inline-item" style={{ color: "#be5a0e", fontWeight: "bold" }}>({tenantId == d.tenantId && courseId == d.itemId ? d.avgScore : null})&nbsp;</li>
                                                                                                            <li className="list-inline-item"><i className="las la-star"></i></li>
                                                                                                            <li className="list-inline-item"><i className="las la-star"></i></li>
                                                                                                            <li className="list-inline-item"><i className="las la-star"></i></li>
                                                                                                            <li className="list-inline-item"><i className="las la-star"></i></li>
                                                                                                            <li className="list-inline-item"><i className="las la-star-half-alt"></i></li>
                                                                                                        </>
                                                                                                        : tenantId == d.tenantId && courseId == d.itemId ? d.avgScore == 5 ?
                                                                                                            <>
                                                                                                                <li className="list-inline-item" style={{ color: "#be5a0e", fontWeight: "bold" }}>({tenantId == d.tenantId && courseId == d.itemId ? d.avgScore : null})&nbsp;</li>
                                                                                                                <li className="list-inline-item"><i className="las la-star"></i></li>
                                                                                                                <li className="list-inline-item"><i className="las la-star"></i></li>
                                                                                                                <li className="list-inline-item"><i className="las la-star"></i></li>
                                                                                                                <li className="list-inline-item"><i className="las la-star"></i></li>
                                                                                                                <li className="list-inline-item"><i className="las la-star"></i></li>
                                                                                                            </>
                                                                                                            : null : null : null : null : null : null : null : null : null : null
                                                                    }
                                                                </>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                            {/* <div className="price">
                                                <h6>{t('course_fee')}</h6>
                                                {fee_validator(courseValue.courseFee)}
                                            </div> */}
                                            <div style={{ position: 'absolute', left: '80%' }}>
                                                {
                                                    CourseCompletionStatus(getActivitiesStatus) && (getCrtStatus == 1) &&
                                                    (<RenderOnAuthenticated>
                                                        {
                                                            (visibility || roleId !== 1) ?
                                                                (dateCompareFunction(convertDate(getServerTime), convertDate(courseValue.enrollmentEndDate)) ?
                                                                    " " :
                                                                    getInstCourseStatus == 2 ? " " :
                                                                        " ")
                                                                : <>
                                                                <Row>
                                                                    <Col sm ={6}>
                                                                    <p style={{fontWeight:"bold", fontSize:"15px"}}>Download<br/>Certificate</p>
                                                                    </Col>
                                                                    <Col sm ={1} style={{padding:"0px", margin:"0px", paddingTop:"10px"}}>
                                                                    <i class="fa fa-arrow-right" aria-hidden="true"></i>
                                                                    </Col>
                                                                    <Col sm ={5} style={{margin:"0px"}}>
                                                                    <button type="button" style={{ background: 'linear-gradient(90deg, #007bc7 0%, #003994 100%)', borderRadius: '5px', border: '0px' }} onClick={handleCertificateView} onMouseOver={changeBackgroundOverCertiButton} onMouseOut={changeBackgroundOutCertiButton}>
                                                                    {!downloadCertificateicon && (
                                                                        <>
                                                                        <i class="las la-certificate la-3x" style={{ color: 'white' }}></i>
                                                                        </>
                                                                    )}
                                                                    {downloadCertificateicon && (
                                                                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{t('Course Certificate')}</Tooltip>}>
                                                                            <i class="las la-download la-3x" style={{ color: 'white' }}></i>
                                                                        </OverlayTrigger>
                                                                    )}
                                                                </button>
                                                                    </Col>
                                                                </Row>
                                                                </>

                                                        }
                                                        {/* {getFileURL !== null && <iframe src={`${CERTIFICATION}/certificate/gencert/687b7083-fda7-461c-b25a-dc142b906a8f/1/1`} style={{width:'0px', height:'0px', color:"white",backgroundColor:"white"}}></iframe>} */}
                                                        <br></br>
                                                    </RenderOnAuthenticated>)}
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div className="course-details-banner">
                                        <img src={`${COURSE_URL}/${courseValue.imageUrl}`} alt="" className="img-fluid" style={{ height: 425, width: 825 }} />
                                    </div> */}
                                    <div className="course-details-banner" style={{ position: 'relative', width: '100%' }}>
                                        {courseValue.video ? (
                                            <video
                                                src={process.env.PUBLIC_URL + `${COURSE_URL}/${courseValue.video}`}
                                                poster={vcimage}
                                                alt="Course Video"
                                                className="img-fluid"
                                                controls
                                                controlsList="nodownload noplaybackrate"
                                                disablePictureInPicture
                                                muted
                                            // autoPlay
                                            />
                                        ) : (
                                            <img
                                                src={process.env.PUBLIC_URL + `${COURSE_URL}/${courseValue.imageUrl}`}
                                                alt="Course Image"
                                                className="img-fluid"
                                            />
                                        )}
                                    </div>
                                </Col>

                                <Col lg="4" md={{ span: 3, offset: 0 }} sm="12">
                                    <div className="single-details-sidbar-courseDetails">
                                        <Row>
                                            <Col md="12">
                                                <div className="course-details-feature">
                                                    <h5 className="title">{t('course_details')}</h5>
                                                    {/* <div className="event-sidebar-timer text-center">
                                                    {
                                                        arrrr.map((d) => {
                                                            return (
                                                                <Timer initialTime={d.time} direction="backward">
                                                                    <p><Timer.Days /><span>Days</span></p>
                                                                    <p><Timer.Hours /><span>Hours</span></p>
                                                                    <p><Timer.Minutes /><span>Minutes</span></p>
                                                                    <p><Timer.Seconds /><span>Seconds</span></p>
                                                                </Timer>
                                                            )
                                                        })
                                                    }

                                                </div> */}
                                                    <ul className="list-unstyled feature-list">
                                                        {/* <li><i className="far fa-calendar-check"></i> {t('enrollment_start_date')}: <span>{convertDate(courseValue.enrollmentStartDate)}</span></li>
                                                        <li><i className="far fa-calendar-check"></i> {t('enrollment_end_date')}: <span>{convertDate(courseValue.enrollmentEndDate)}</span></li>
                                                        <li><i className="far fa-calendar-check"></i> {t('course_start_date')}: <span>{convertDate(courseValue.commencementDate)}</span></li>
                                                        <li><i className="far fa-calendar-check"></i> {t('course_end_date')}: <span>{convertDate(courseValue.closingDate)}</span></li> */}
                                                        <li><i className="far fa-clock"></i> {t('duration')}: <span>{courseValue.duration == 1825 ? "Unlimited" : courseValue.duration} {t('days')}</span></li>
                                                        <li><i className="fas fa-globe-asia"></i> {t('language1')}: <span>{t('english')}</span></li>
                                                        {/* <li><i className="las la-sort-amount-up"></i> Skill Level: <span>Beginner</span></li> */}
                                                        {/* <li><i className="las la-graduation-cap"></i> Subject: <span>Web</span></li> */}
                                                        {/* <li><i className="las la-book"></i> Lectures: <span>51</span></li> */}
                                                        <li><i className="far fa-bookmark"></i> Enrolled Learners: <span>{userCount}</span></li>
                                                        <li><i className="fas fa-certificate"></i> {t('certification')}: <span>{t('yes')}</span></li>
                                                    </ul>
                                                    <RenderOnAnonymous>
                                                        <button type="button" className="enroll-btn" onClick={UserService.doLogin}>{t('please_login_to_enroll_this_course')}</button>
                                                    </RenderOnAnonymous>
                                                    <RenderOnAuthenticated>
                                                        {/* {
                                                        visibility === true ?
                                                            <button type="button" className="enroll-btn" onClick={() => enrollCourseHandler()}>{t('enroll_course')}</button> :
                                                            <button type="button" className="enroll-btn" onClick={() => enrollCourseHandler(userId, courseId)} disabled={true}>{t('already_enrolled')}</button>
                                                    }
                                                    <br></br> */}
                                                        {
                                                            (visibility === true && feeStatus === false)
                                                                ?
                                                                ((roleId !== 1 && roleId !== 4 && roleId !== 5 && roleId !== 7)
                                                                    ?
                                                                    ((dateCompareFunction(getServerTime, courseValue.enrollmentEndDate))
                                                                        ?
                                                                        <button type="button" className="enroll-btn" disabled={true}>{t('course_enrollment')}</button>
                                                                        :
                                                                        roleId == 2 ? "" :
                                                                            //(dateLimit.isValid() && dateLimit.isAfter(now)) ?  
                                                                            (dateLimit.isValid() && dateLimit.isAfter(getServerTime)) ?
                                                                                // (courseValue.courseFee > 0)
                                                                                //             ?
                                                                                //             <button type="button" onClick={PaymentParameterView} className="enroll-btn" disabled={CourseEnrollment.isLoading ? "true" : ""}>  {CourseEnrollment.isLoading ? (<> {t('loading')}</>) : (<>Pay to Enroll</>)}</button>
                                                                                //             :
                                                                                <button type="button" className="enroll-btn" onClick={() => enrollCourseHandler()} disabled={CourseEnrollment.isLoading ? "true" : ""}>{CourseEnrollment.isLoading ? (<> {t('loading')}</>) : (<>{t('enroll_course')}</>)}</button>
                                                                                :
                                                                                <button type="button" className="enroll-btn" disabled={true} style={{ background: 'linear-gradient(#df2c14, #c61a09)' }}>{t('course_close')}</button>
                                                                    )
                                                                    : ((roleId === 4 || roleId === 5 || roleId === 7)
                                                                        ?
                                                                        (courseValue.courseFee > 0 && roleId === 7)
                                                                            ?
                                                                            <button type="button" onClick={() => { paymentForCourse(courseId, tenantId) }} className="enroll-btn" disabled={CourseEnrollment.isLoading ? "true" : ""}>  {CourseEnrollment.isLoading ? (<> {t('loading')}</>) : (<>{t('pay_to_enroll')}</>)}</button>
                                                                            :
                                                                            ((roleId === 4) ?
                                                                                <button type="button" className="enroll-btn" onClick={() => enrollCourseHandler(userId, courseId)} disabled={true}>{t('Waiting for Admin approval')}</button>
                                                                                :
                                                                                <button type="button" className="enroll-btn" onClick={() => enrollCourseHandler(userId, courseId)} disabled={true}>{t('approval Rejected by Admin')}</button>)
                                                                        :
                                                                        <button type="button" className="enroll-btn" onClick={() => enrollCourseHandler(userId, courseId)} disabled={true}>{t('approval Rejected by Admin')}</button>))
                                                                :
                                                                (dateLimit.isValid() && dateLimit.isAfter(now))
                                                                    ?
                                                                    (courseValue.courseFee > 0 && roleId === 7)
                                                                        ?
                                                                        <button type="button" onClick={() => { paymentForCourse(courseId, tenantId) }} className="enroll-btn" disabled={CourseEnrollment.isLoading ? "true" : ""}>  {CourseEnrollment.isLoading ? (<> {t('loading')}</>) : (<>{t('pay_to_enroll')}</>)}</button>
                                                                        :
                                                                        ((roleId === 1)
                                                                            ?
                                                                            <button type="button" className="enroll-btn" onClick={() => enrollCourseHandler(userId, courseId)} disabled={true}>{t('already_enrolled')}</button>
                                                                            :
                                                                            ((roleId === 4 || roleId === 5) ? (roleId === 4) ? <button type="button" className="enroll-btn" disabled={true}>{t('Waiting for Admin approval')}</button> : <button type="button" className="enroll-btn" disabled={true} style={{ background: 'linear-gradient(#df2c14, #c61a09)' }}>{t('Approval Rejected by Admain')}</button> : null))
                                                                    :
                                                                    (<button type="button" className="enroll-btn" disabled={true} style={{ background: 'linear-gradient(#df2c14, #c61a09)' }}>{t('course_close')}</button>)
                                                        }
                                                        <br></br>
                                                    </RenderOnAuthenticated>
                                                </div>
                                            </Col>
                                        </Row>

                                    </div>
                                    <div className='single-details-sidbar-announcement'>
                                        {/* <Col md="12">
                                                <PopularCourse />
                                            </Col> */}
                                        <Row>
                                            <RenderOnAuthenticated>
                                                {(visibility || roleId !== 1) ?
                                                    null
                                                    :
                                                    (courseValue.courseType?.toLowerCase() == "free" || feeStatus === true) ? (<Col md="12" >
                                                        <div>
                                                            <div className="course-details-feature">
                                                                <Dropdown autoClose='inside' >
                                                                    <h5 className="title">{t('announcement')}
                                                                        <i style={{ marginLeft: '110px' }} className="las la-bullhorn la-lg " >
                                                                        </i>{
                                                                            getCourseAnnouncement.length == 0
                                                                                ?
                                                                                null
                                                                                :
                                                                                <span className="badge">{getCourseAnnouncement.length}</span>
                                                                        }
                                                                    </h5>
                                                                    <Dropdown.Toggle className='view-announcement'>{t('show_announcement')}</Dropdown.Toggle>

                                                                    <Dropdown.Menu style={{ width: "100%" }}>
                                                                        <div className='announcement-content'>
                                                                            {
                                                                                getCourseAnnouncement.length === 0 ? <li style={{ fontWeight: 'bold', padding: '10px' }} className="noti-text-dup">{t('no_announcment')}</li> :
                                                                                    <ul className="list-unstyled feature-list" style={{ overflowY: 'auto', height: "250px" }}>
                                                                                        {getCourseAnnouncement.map((data) => {
                                                                                            return (
                                                                                                <li style={{ padding: '10px' }} className="noti-text-dup" onClick={() => AnnouncementModal(data.title, data.body, dateConverter(data.publihFrom))}>{data.title}</li>
                                                                                            )
                                                                                        })}
                                                                                    </ul>
                                                                            }
                                                                        </div>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>


                                                            </div>

                                                        </div>

                                                    </Col>) : null
                                                }
                                            </RenderOnAuthenticated>
                                        </Row>

                                    </div>
                                </Col>
                            </div >
                            {/* </Col> */}
                        </Row>
                    </Container>
                </section>
                <section className="course-details-area" id='Tab'>
                    <Container fluid>
                        <Row>
                            <div className="course-tab-list" >
                                <Col md={{ span: 10, offset: 1 }}>
                                    <Tab.Container defaultActiveKey="overview" style={{}}>
                                        <Nav className="justify-content-center" onClick={() => scrollToView()}>
                                            <Nav.Item>
                                                <Nav.Link eventKey="overview">{t('overview')}</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                {
                                                    isDecodeValid === "VALID"
                                                        ?
                                                        <>
                                                            <Nav.Link onClick={() => { callCourseDelievery(); }} eventKey="curriculum" >{t('curriculum')}</Nav.Link>
                                                        </>
                                                        :
                                                        <>
                                                        </>
                                                }
                                            </Nav.Item>
                                            <RenderOnAuthenticated>
                                                {(visibility || roleId !== 1) ?
                                                    null
                                                    :
                                                    (courseValue.courseType?.toLowerCase() == "free" || feeStatus === true) ? (<Nav.Item>
                                                        <Nav.Link eventKey="assignment">{t('assignment')}</Nav.Link>
                                                    </Nav.Item>) : null
                                                }
                                            </RenderOnAuthenticated>
                                            <Nav.Item>
                                                <Nav.Link eventKey="instructor">{t('instructor')}</Nav.Link>
                                            </Nav.Item>
                                            <RenderOnAuthenticated>
                                                {(visibility || roleId !== 1) ?
                                                    null
                                                    :
                                                    (courseValue.courseType?.toLowerCase() == "free" || feeStatus === true) ? (<Nav.Item>
                                                        <Nav.Link eventKey="feedback">{t('feedback')}</Nav.Link>
                                                    </Nav.Item>) : null
                                                }
                                            </RenderOnAuthenticated>
                                            {/* <RenderOnAuthenticated>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="discussion">Discussion</Nav.Link>
                                                    </Nav.Item>
                                                </RenderOnAuthenticated> */}
                                            <Nav.Item>
                                                <Nav.Link eventKey="review">{t('reviews')}</Nav.Link>
                                            </Nav.Item>
                                            <RenderOnAuthenticated>
                                                {(visibility || roleId !== 1) ?
                                                    null
                                                    :
                                                    (courseValue.courseType?.toLowerCase() == "free" || feeStatus === true) ?
                                                        (<Nav.Item>
                                                            <Nav.Link eventKey="assessment">{t('assessment')}</Nav.Link>
                                                        </Nav.Item>) : null
                                                }
                                            </RenderOnAuthenticated>

                                            <RenderOnAuthenticated>
                                                {(visibility || roleId !== 1) ?
                                                    null
                                                    : (courseValue.courseType?.toLowerCase() == "free" || feeStatus === true) ?
                                                        (<Nav.Item>
                                                            <Nav.Link eventKey="discussion">{t('discussion')}</Nav.Link>
                                                        </Nav.Item>) : null
                                                }
                                            </RenderOnAuthenticated>
                                            <RenderOnAuthenticated>
                                                {(visibility || roleId !== 1) ?
                                                    null
                                                    :
                                                    (courseValue.courseType?.toLowerCase() == "free" || feeStatus === true) ? (<Nav.Item>
                                                        <Nav.Link eventKey="query">{t('query')}</Nav.Link>
                                                    </Nav.Item>) : null
                                                }
                                            </RenderOnAuthenticated>

                                            {getCertiStatus ?
                                                <Nav.Item>
                                                    <Nav.Link eventKey="certificate">{t('certificate')}</Nav.Link>
                                                </Nav.Item>
                                                : null
                                            }
                                        </Nav>
                                        <Tab.Content >
                                            <Tab.Pane eventKey="query" className="overview-tab">
                                                {
                                                    isDecodeValid === "VALID"
                                                        ?
                                                        <>
                                                            <RenderOnAuthenticated>
                                                                <Query courseid={courseId} tenantid={tenantId} userid={userId} itemid={courseId} />
                                                            </RenderOnAuthenticated>
                                                        </>
                                                        :
                                                        <>
                                                        </>
                                                }
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="discussion" className="overview-tab">
                                                {
                                                    isDecodeValid === "VALID" ?
                                                        <>
                                                            <RenderOnAuthenticated>
                                                                <DiscussionMain courseid={courseId} tenantid={tenantId} userid={userId} itemid={courseId} />
                                                            </RenderOnAuthenticated>
                                                        </>
                                                        :
                                                        <></>
                                                }

                                            </Tab.Pane>
                                            <Tab.Pane eventKey="overview" className="overview-tab">
                                                <div className="course-desc">
                                                    <h5>{t('course_description')}</h5>
                                                    <p dangerouslySetInnerHTML={{ __html: courseValue.generalDetails }} style={{ textAlign: "justify" }}></p>
                                                </div>
                                                <div className="course-feature">
                                                    <h5>{t('course_prerequisite')}</h5>
                                                    <p dangerouslySetInnerHTML={{ __html: courseValue.prerequisite }} style={{ textAlign: "justify" }}></p>
                                                </div>
                                                <div className="course-feature">
                                                    <h5>{t('course_objective')}</h5>
                                                    <p dangerouslySetInnerHTML={{ __html: courseValue.objective }} style={{ textAlign: "justify" }}></p>
                                                </div>

                                                <div className="course-share">
                                                    <h5>{t('share_this_course')}</h5>
                                                    <ul className="social list-unstyled list-inline">
                                                        {/* <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-facebook-f"></i></a></li>
                                                            <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-twitter"></i></a></li>
                                                            <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-linkedin-in"></i></a></li> */}
                                                        <li className="list-inline-item">
                                                            <FacebookShareButton url={url} quote={"Share this course"} >
                                                                <FacebookIcon logoFillColor="white" round={true} size={32}></FacebookIcon>
                                                            </FacebookShareButton>
                                                        </li>
                                                        <li className="list-inline-item">
                                                            <TwitterShareButton url={url} quote={"Share this course"}>
                                                                <TwitterIcon logoFillColor="white" round={true} size={32}></TwitterIcon>
                                                            </TwitterShareButton>
                                                        </li>
                                                        <li className="list-inline-item">
                                                            <LinkedinShareButton url={url} quote={"Share this course"}>
                                                                <LinkedinIcon logoFillColor="white" round={true} size={32}></LinkedinIcon>
                                                            </LinkedinShareButton>
                                                        </li>
                                                        <li className="list-inline-item">
                                                            <WhatsappShareButton url={url} title="Share course">
                                                                <WhatsappIcon logoFillColor="white" round={true} size={32}></WhatsappIcon>
                                                            </WhatsappShareButton>
                                                        </li>

                                                    </ul>
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="curriculum" className="curriculum-tab">
                                                <div className="course-element">
                                                    {/* <h5>{getcourseStructure.label}
                                                            {visibility ? null : <Link className="nav-link pull-right" onClick={() => CourseDelivery(courseValue.courseId, 1)}>Content View</Link>}
                                                        </h5> */}
                                                    {/* {showContentDependOnDate && (<div>
                                                            {getcourseStructure1.map((d) => {
                                                                return (

                                                                    
                                                                    <div className="course-item">
                                                                        <button className="course-button active" onClick={() => { toggleHandler(d.key) }}>{d.label} <span>{sumOfAllContentDuration(d.nodes)} Min</span></button>
                                                                        {visibility ? null : <div className={`course-content ${d.isActive ? "" : "show"}`}>
                                                                            <ul className="list-unstyled">
                                                                                {d.nodes.map((f) => {
                                                                                    return (
                                                                                        <li>
                                                                                            <span className="play-icon"><i className={f.nodetype == "pdf" ? "fas fa-file-pdf" : f.nodetype == "docx" || f.nodetype == "doc" ? "fas fa-file-word" :
                                                                                                f.nodetype == "html" ? "fab fa-html5" : f.nodetype == "txt" ? "fas fa-file-alt" : f.nodetype == "scorm" ? "far fa-file-archive" : f.nodetype == "youtube" ? "fab fa-youtube" :
                                                                                                    f.nodetype == "mp4" || f.nodetype == "webm" || f.nodetype == "ogg" ? "fas fa-file-video" : f.nodetype == "jpg" || f.nodetype == "png" || f.nodetype == "jpeg" ? "fas fa-file-image"
                                                                                                        : null} style={{ cursor: 'pointer' }} onClick={() => CourseDelivery(courseValue.courseId, 1)}></i> {f.label}</span>
                                                                                            <span className="lecture-duration">{f.duration}:00</span>
                                                                                        </li>
                                                                                    )
                                                                                })}
                                                                            </ul>
                                                                        </div>}
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>)} */}
                                                    {/* {(!showContentDependOnDate || visibility === true) && (<div>
                                                    {getcourseStructure1.map((d) => {
                                                        return (
                                                            <div className="course-item">
                                                                <button className="course-button active" onClick={() => { toggleHandler(d.key) }}>{d.label} <span>{sumOfAllContentDuration(d.nodes)} Min</span></button>
                                                                {visibility ? null : <div className={`course-content ${d.isActive ? "" : "show"}`}>
                                                                    <ul className="list-unstyled">
                                                                        {d.nodes.map((f) => {
                                                                            return (
                                                                                <li>
                                                                                    <span className="play-icon"><i className={f.nodetype == "pdf" ? "fas fa-file-pdf" : f.nodetype == "docx" || f.nodetype == "doc" ? "fas fa-file-word" :
                                                                                        f.nodetype == "html" ? "fab fa-html5" : f.nodetype == "txt" ? "fas fa-file-alt" : f.nodetype == "scorm" ? "far fa-file-archive" : f.nodetype == "youtube" ? "fab fa-youtube" :
                                                                                            f.nodetype == "mp4" || f.nodetype == "webm" || f.nodetype == "ogg" ? "fas fa-file-video" : f.nodetype == "jpg" || f.nodetype == "png" || f.nodetype == "jpeg" ? "fas fa-file-image"
                                                                                                : null} style={{ cursor: 'pointer' }} onClick={() => courseStructurContentView(f.nodetype, f.filePath)}></i> {f.label}</span>
                                                                                    <span className="lecture-duration">{f.duration}:00</span>
                                                                                </li>
                                                                            )
                                                                        })}
                                                                    </ul>
                                                                </div>}
                                                            </div>
                                                        )
                                                    })}
                                                </div>)} */}
                                                    {((!handleContentDependOnDate() || visibility === true) && (getcourseStructure1 !== undefined))
                                                        ?
                                                        <div>
                                                            {getcourseStructure1.map((d) => {
                                                                return (
                                                                    <div className="course-item">
                                                                        <button className="course-button active" onClick={() => { toggleHandler(d.key) }}>{d.label} <span>{sumOfAllContentDuration(d.nodes)} Min</span></button>
                                                                        {(visibility || roleId !== 1) ? <></> : (courseValue.courseType?.toLowerCase() == "free" || feeStatus === true) ? (<div className={`course-content ${d.isActive ? "" : "show"}`}>
                                                                            <ul className="list-unstyled">
                                                                                {d.nodes.map((f) => {
                                                                                    return (
                                                                                        <li>
                                                                                            <span className="play-icon"><i className={f.nodetype == "pdf" ? "fas fa-file-pdf" : f.nodetype == "docx" || f.nodetype == "doc" ? "fas fa-file-word" :
                                                                                                f.nodetype == "html" ? "fab fa-html5" : f.nodetype == "txt" ? "fas fa-file-alt" : f.nodetype == "scorm" ? "far fa-file-archive" : f.nodetype == "youtube" ? "fab fa-youtube" :
                                                                                                    f.nodetype == "mp4" || f.nodetype == "webm" || f.nodetype == "ogg" ? "fas fa-file-video" : f.nodetype == "jpg" || f.nodetype == "png" || f.nodetype == "jpeg" ? "fas fa-file-image"
                                                                                                        : null} style={{ cursor: 'pointer' }} onClick={() => courseStructurContentView(f.nodetype, f.filePath)}></i> {f.label}</span>
                                                                                            <span className="lecture-duration">{f.duration}:00</span>
                                                                                        </li>
                                                                                    )
                                                                                })}
                                                                            </ul>
                                                                        </div>) : null}
                                                                    </div>
                                                                )
                                                            }
                                                            )
                                                            }
                                                        </div>
                                                        :
                                                        <></>
                                                    }


                                                </div>

                                                {/* <div className="items"> {items}</div > */}

                                                {/* <div className="course-element">
                                                        <h5>{getcourseStructure.label}</h5>
                                                        <div className="course-item">
                                                            <Tree data={getcourseStructure.nodes} />
                                                        </div>
                                                    </div> */}

                                            </Tab.Pane>
                                            <Tab.Pane eventKey="assessment" className="assessment-tab">
                                                {
                                                    handleContentDependOnDate() && (
                                                        <>
                                                            {
                                                                isDecodeValid === "VALID" ?
                                                                    <>
                                                                        <RenderOnAuthenticated>
                                                                            <DataTableAssessment tenantId={tenantId} courseId={courseId} />
                                                                        </RenderOnAuthenticated>
                                                                        <br /><br />
                                                                        {/* <RenderOnAuthenticated>
                                                                        <EnrolledAssignment courseID={courseId} tenantID={tenantId} />
                                                                    </RenderOnAuthenticated> */}
                                                                    </>
                                                                    :
                                                                    <>
                                                                    </>
                                                            }
                                                        </>
                                                    )
                                                }
                                                {!handleContentDependOnDate() && (<RenderOnAuthenticated>
                                                    <p>{t('course_closed')}</p>
                                                </RenderOnAuthenticated>)}

                                            </Tab.Pane>
                                            <Tab.Pane eventKey="instructor" className="instructor-tab">
                                                <h5>{t('course_instructor')}</h5>
                                                <div className='instructor-box'>
                                                    <div className="instructor-item">
                                                        {
                                                            getInstructor?.map((data, i) => {
                                                                return (
                                                                    <Row>
                                                                        {/* <Col md="4"> */}
                                                                        <div className="instructor-img">
                                                                            <img src={`${getInstructorImg.img}${data.learnerUsername}`} alt="" className="img-fluid" style={{ width: '110px' }} />
                                                                        </div>
                                                                        {/* </Col> */}
                                                                        <Col md="8">
                                                                            <div className="instructor-content">
                                                                                <div className="instructor-box">
                                                                                    <div className="top-content " >
                                                                                        {/* d-flex justify-content-between  */}
                                                                                        <div >
                                                                                            <div className="instructor-name">
                                                                                                <h6>{data.firstName} {data.lastName
                                                                                                }</h6>
                                                                                                <p>{courseValue.inst_profile}</p>
                                                                                                <br />
                                                                                                {
                                                                                                    UserService.isLoggedIn() &&
                                                                                                    <p>{t('emailid')} - {data.email}</p>
                                                                                                }

                                                                                            </div>
                                                                                            <div className="instructor-social">
                                                                                                <ul className="social list-unstyled list-inline">
                                                                                                    {data.facebookId === "" ? null :
                                                                                                        <li className="list-inline-item" style={{ margin: 5, marginTop: 10 }}><a target="_blank"  rel="nofollow noopener noreferrer" href={"https://www.facebook.com/" + data.facebookId}><i className="fab fa-facebook-f"></i></a></li>
                                                                                                    }
                                                                                                    {data.twitterId === "" ? null :
                                                                                                        <li className="list-inline-item" style={{ margin: 5, marginTop: 10 }}><a href={"https://www.twitter.com/" + data.twitterId} target="_blank"  rel="nofollow noopener noreferrer"><i className="fab fa-twitter"></i></a></li>
                                                                                                    }
                                                                                                    {data.linkedinId === "" ? null :
                                                                                                        <li className="list-inline-item" style={{ margin: 5, marginTop: 10 }}><a href={"https://www.linkedin.com/in/" + data.linkedinId} target="_blank"  rel="nofollow noopener noreferrer"><i className="fab fa-linkedin-in"></i></a></li>
                                                                                                    }
                                                                                                    {data.youtubeId === "" ? null :
                                                                                                        <li className="list-inline-item" style={{ margin: 5, marginTop: 10 }}><a href={"https://www.youtube.com/" + data.youtubeId} target="_blank"  rel="nofollow noopener noreferrer"><i className="fab fa-youtube"></i></a></li>
                                                                                                    }
                                                                                                    {data.skypeId === "" ? null :
                                                                                                        <li className="list-inline-item" style={{ margin: 5, marginTop: 10 }}><a href={"https://www.skype.com/" + data.skypeId} target="_blank"  rel="nofollow noopener noreferrer"><i className="fab fa-skype"></i></a></li>
                                                                                                    }
                                                                                                </ul>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="instructor-desk">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                )
                                                            })
                                                        }
                                                    </div>

                                                </div>
                                            </Tab.Pane>
                                            {/* <Tab.Pane eventKey="feedback">
                                            <RenderOnAuthenticated>
                                                <CourseFeedback typeid={1} cid={courseId} />
                                            </RenderOnAuthenticated>
                                        </Tab.Pane> */}
                                            <Tab.Pane eventKey="feedback">
                                                {
                                                    isDecodeValid === "VALID"
                                                        ?
                                                        <>
                                                            <RenderOnAuthenticated>
                                                                <CourseFeedbackList typeid={1} cid={courseId} />
                                                            </RenderOnAuthenticated>
                                                        </>
                                                        :
                                                        <>
                                                        </>
                                                }

                                            </Tab.Pane>
                                            <Tab.Pane eventKey="review" className="review-tab">
                                                <Row>
                                                    <div className="review-comments" style={{ width: '100%', margin: '0px', marginBottom: '20px' }}>
                                                        <h5>{t('course_reviews')}</h5>
                                                        {

                                                            getRating.map((rate) => {
                                                                const timestamp = Date.parse(rate.creationTime);
                                                                const starrating = (rate.rating)
                                                                return (
                                                                    < div className="comment-box d-flex" >
                                                                        <div className="comment-image">
                                                                            <img src={rate.profilePicUrl} alt="" />
                                                                        </div>
                                                                        <div className="comment-content">
                                                                            <div className="content-title d-flex justify-content-between">
                                                                                <div className="comment-writer">
                                                                                    <h6>{rate.firstName} {rate.lastName}</h6>
                                                                                    <p>{new Intl.DateTimeFormat('en-in', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(timestamp)}</p>
                                                                                    <ul className="list-unstyled list-inline">
                                                                                        {rate.rating == 1 ?
                                                                                            <>
                                                                                                <li className="list-inline-item"><i className="las la-star"></i></li>
                                                                                                <li className="list-inline-item" style={{ color: "#be5a0e", fontWeight: "bold" }}>({rate.rating})</li>
                                                                                            </>
                                                                                            : rate.rating == 2 ?
                                                                                                <>
                                                                                                    <li className="list-inline-item"><i className="las la-star"></i></li>
                                                                                                    <li className="list-inline-item"><i className="las la-star"></i></li>
                                                                                                    <li className="list-inline-item" style={{ color: "#be5a0e", fontWeight: "bold" }}>({rate.rating})</li>
                                                                                                </>
                                                                                                : rate.rating == 3 ?
                                                                                                    <>
                                                                                                        <li className="list-inline-item"><i className="las la-star"></i></li>
                                                                                                        <li className="list-inline-item"><i className="las la-star"></i></li>
                                                                                                        <li className="list-inline-item"><i className="las la-star"></i></li>
                                                                                                        <li className="list-inline-item" style={{ color: "#be5a0e", fontWeight: "bold" }}>({rate.rating})</li>
                                                                                                    </>
                                                                                                    : rate.rating == 4 ?
                                                                                                        <>
                                                                                                            <li className="list-inline-item"><i className="las la-star"></i></li>
                                                                                                            <li className="list-inline-item"><i className="las la-star"></i></li>
                                                                                                            <li className="list-inline-item"><i className="las la-star"></i></li>
                                                                                                            <li className="list-inline-item"><i className="las la-star"></i></li>
                                                                                                            <li className="list-inline-item" style={{ color: "#be5a0e", fontWeight: "bold" }}>({rate.rating})</li>
                                                                                                        </>
                                                                                                        : rate.rating == 5 ?
                                                                                                            <>
                                                                                                                <li className="list-inline-item"><i className="las la-star"></i></li>
                                                                                                                <li className="list-inline-item"><i className="las la-star"></i></li>
                                                                                                                <li className="list-inline-item"><i className="las la-star"></i></li>
                                                                                                                <li className="list-inline-item"><i className="las la-star"></i></li>
                                                                                                                <li className="list-inline-item"><i className="las la-star"></i></li>
                                                                                                                <li className="list-inline-item" style={{ color: "#be5a0e", fontWeight: "bold" }}>({rate.rating})</li>
                                                                                                            </>
                                                                                                            : null}
                                                                                    </ul>
                                                                                </div>
                                                                                <div className="reply-btn">
                                                                                    {rate.learnerUsername == userId ? <><button type="button" onClick={() => UpdateReview(rate.reviewText, rate.rating, rate.reviewId, userId)}>
                                                                                        <i className="fas fa-edit"></i></button>&nbsp;&nbsp;&nbsp;
                                                                                        <button type="button" onClick={() => { DeleteReview(rate.reviewId); }}><i className="fas fa-trash-alt"></i></button></>
                                                                                        : null}
                                                                                </div>

                                                                            </div>
                                                                            <div className="comment-desc">
                                                                                <p>{rate.reviewText}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                    {
                                                        reviewTextBoxCondition === true
                                                            ?
                                                            <>
                                                                {
                                                                    reviewvisibility == true || reviewvisibilitybfrlgin == true ?
                                                                        <>
                                                                            <section className="registration-area" id="formRegistration">
                                                                                <Container>
                                                                                    <Row>
                                                                                        <Col md="12">
                                                                                            <div className="registration-box">
                                                                                                <div className="registration-title text-center">
                                                                                                    <h3> </h3>
                                                                                                </div>
                                                                                            </div>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </Container>
                                                                            </section>
                                                                        </>

                                                                        :
                                                                        ((visibility || roleId !== 1) ?
                                                                            null
                                                                            : (courseValue.courseType?.toLowerCase() == "free" || feeStatus === true) ? (<>
                                                                                <div className="review form" style={{ width: '100%' }}>
                                                                                    <h5>{t('rate_course')}</h5>
                                                                                    <br></br>
                                                                                    <Styles1>
                                                                                        <form id="form6" className="form review-comment-form">
                                                                                            <Row>
                                                                                                <Col md="12">
                                                                                                    <div className="star-rating">
                                                                                                        <input type="radio" name="rating" value="5" defaultValue={getRate.rating} onChange={e => setRate({ ...getRate, rating: e.target.value })} id="rate-5" />
                                                                                                        <label htmlFor="rate-5" className="las la-star"></label>
                                                                                                        <input type="radio" value="4" onChange={e => setRate({ ...getRate, rating: e.target.value })} name="rating" id="rate-4" />
                                                                                                        <label htmlFor="rate-4" className="las la-star"></label>
                                                                                                        <input type="radio" value="3" onChange={e => setRate({ ...getRate, rating: e.target.value })} name="rating" id="rate-3" />
                                                                                                        <label htmlFor="rate-3" className="las la-star"></label>
                                                                                                        <input type="radio" value="2" onChange={e => setRate({ ...getRate, rating: e.target.value })} name="rating" id="rate-2" />
                                                                                                        <label htmlFor="rate-2" className="las la-star"></label>
                                                                                                        <input type="radio" value="1" onChange={e => setRate({ ...getRate, rating: e.target.value })} name="rating" id="rate-1" />
                                                                                                        <label htmlFor="rate-1" className="las la-star"></label>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <p style={{ fontSize: 12, color: "red" }}>
                                                                                                            {getRate.ratingError}
                                                                                                        </p>
                                                                                                    </div>
                                                                                                </Col>
                                                                                                <Col md="12">
                                                                                                    <p className="form-control">
                                                                                                        <textarea name="reviewText"

                                                                                                            id="desc6" onChange={e => setRate({ ...getRate, reviewText: e.target.value })} placeholder={t('submit_review_placeholder')}></textarea>
                                                                                                        <span className="input-msg6"></span>
                                                                                                    </p>
                                                                                                    <p style={{ fontSize: 12, color: "red" }}>
                                                                                                        {getRate.reviewTextError}
                                                                                                    </p>
                                                                                                </Col>

                                                                                                <Col md="12">
                                                                                                    {loading ? <button type="button" ><div class="spinner-border" role="status">
                                                                                                        <span class="sr-only">{t('loading')}</span>
                                                                                                    </div>  {t('submitting')}</button>
                                                                                                        : <button type="button" onClick={() => { saverating() }}>{t('submit')}</button>}
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </form>
                                                                                    </Styles1>
                                                                                </div>
                                                                            </>) : null)
                                                                }
                                                            </>
                                                            :
                                                            <>
                                                                {
                                                                    <>
                                                                        <section className="registration-area" id="formRegistration">
                                                                            <Container>
                                                                                <Row>
                                                                                    <Col md="12">
                                                                                        <div className="registration-box">
                                                                                            <div className="registration-title text-center">
                                                                                                <h5>{t('submit_review_placeholder')}</h5>
                                                                                            </div>
                                                                                        </div>
                                                                                    </Col>
                                                                                </Row>
                                                                            </Container>
                                                                        </section>
                                                                    </>
                                                                }
                                                            </>
                                                    }

                                                    <br></br>
                                                    <br></br>
                                                </Row>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="assignment" >

                                                {/* <AssignmentMain userId={userId} courseId={courseId} tenantId={tenantId} /> */}


                                                {handleContentDependOnDate() &&
                                                    (
                                                        <>
                                                            {
                                                                isDecodeValid === "VALID"
                                                                    ?
                                                                    <>
                                                                        <RenderOnAuthenticated>
                                                                            <EnrolledAssignment courseID={courseId} tenantID={tenantId} />
                                                                            {/* <AssignmentMain userId={userId} courseId={courseId} tenantId={tenantId} /> */}
                                                                        </RenderOnAuthenticated>
                                                                    </>
                                                                    :
                                                                    <></>
                                                            }
                                                        </>

                                                    )
                                                }
                                                {!handleContentDependOnDate() && (<RenderOnAuthenticated>
                                                    <p>{t('course_closed')}</p>
                                                </RenderOnAuthenticated>)}
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="certificate" >
                                                <Button variant="outline-info" onClick={() => certificateDownload()}>{t('download')} <i class="las la-download la-lg"></i></Button>
                                                <Button variant="outline-info" onClick={() => certificateView()}>{t('view')} <i class="las la-eye la-lg"></i></Button>
                                                {/* <iframe src={getCertificateURl} width="725px" height="800px" /> */}
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Tab.Container>
                                </Col>
                            </div>
                        </Row>
                    </Container>
                </section>
                <Modal
                    centered show={getModalState.show} onHide={() => handleModal2()}>
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            {t('provide_the_reply')}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ReplyForm rrId={getreviewId} />
                    </Modal.Body>
                    <Modal.Footer>

                    </Modal.Footer>
                </Modal>
                <Modal
                    centered show={getModalState1.show} onHide={() => handleModal3()}>
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            {t('provide_the_reply')}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <UpdateReviewform reviewdata={getreviewText} starrating={getstarrate} courseid={getcourseId} ureviewid={getureviewId} learnerId={userId} tenantId={tenantId} />
                    </Modal.Body>
                    <Modal.Footer>

                    </Modal.Footer>
                </Modal>
                <Modal
                    centered show={getModalState5.show} onHide={() => handleModal5()}>
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            {t('review_deleted_successfully')}
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Footer>

                    </Modal.Footer>
                </Modal>
                <Modal size="md" aria-labelledby="contained-modal-title-vcenter" centered show={getAnnouncmentModal} backdrop="static" keyboard={false}>
                    <Modal.Header>
                        <Modal.Title id="contained-modal-title-vcenter">
                            {announcementData.title}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {ReactHtmlParser(ReactHtmlParser(announcementData.body))}
                    </Modal.Body>
                    <Modal.Footer>
                        <span style={{ fontSize: '10px', position: 'sticky' }}>{announcementData.date}</span>
                        <Button onClick={() => setAnnouncementModal(false)}>{t('close')}</Button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={getViewModalState}
                >
                    <Modal.Header>
                        {/* <Modal.Title id="contained-modal-title-vcenter">
                            
                        </Modal.Title> */}
                    </Modal.Header>
                    <Modal.Body>
                        <iframe src={getCertificateURl} width="765px" height="800px" />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={() => setViewModalState(false)} className="btn btn-danger">{t('close')}</Button>
                    </Modal.Footer>
                </Modal>

                {/* ContentView model code start here*/}
                <Modal
                    size="xl" centered show={getUrlModal.show} onHide={() => UrlModalHide()} backdrop="static">
                    <Modal.Header >
                        <Modal.Title id="contained-modal-title-vcenter">
                            {getContentType === "zip" ? <i class="fas fa-file-archive" style={{ fontSize: "25px", color: "#fdbf00" }}> Zip</i>
                                : getContentType === "pdf" ? <i class="fas fa-file-pdf" style={{ fontSize: "25px", color: "#b30b00" }}> PDF</i>
                                    : getContentType === "jpg" || getContentType === "png" || getContentType === "jpeg" ? <i class="fas fa-file-image" style={{ fontSize: "25px", color: "#b2b1ff" }}> Image</i>
                                        : getContentType === "html" ? <i class="fab fa-html5" style={{ fontSize: "25px", color: "#e54c21" }}> Html</i>
                                            : getContentType === "ogg" || getContentType === "webm" || getContentType === "mp4" ? <i class="fas fa-file-video" style={{ fontSize: "25px", color: "#8cee02" }}> Video</i>
                                                // : getContentType === "txt" ? <i class="fas fa-file-alt" style={{ fontSize: "25px", color: "#2766a0" }}> Text</i>
                                                // : getContentType === "doc" || getContentType === "docx" ? <i class="fas fa-file-word" style={{ fontSize: "25px", color: "#1e62b4" }}> Doc</i>
                                                : getContentType === "scorm" ? <i class="far fa-file-archive" style={{ fontSize: "25px", color: "#665245" }}> Scorm</i>
                                                    : getContentType === "youtube" ? <i class="fab fa-youtube" style={{ fontSize: "25px", color: "#665245" }}> YouTube</i>
                                                        : null}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            getContentType === "jpg" || getContentType === "png" || getContentType === "jpeg" ? <img src={DMS_URL + `/${getUrl}`} width="1100" height="800" />
                                : getContentType === "pdf" ? <iframe width="1100" height="800" src={DMS_URL + `/${getUrl}`} type="application/pdf" ></iframe>
                                    : getContentType === "mp4" ? <div> <Videojs {...videoJsOptions} /></div>
                                        // : getContentType === "docx" ? <iframe width="100%" height="100%" src={DMS_URL+`/${getUrl}`} ></iframe>
                                        : getContentType === "html" ? <iframe width="1100" height="800" src={DMS_URL + `/${getUrl}`} ></iframe>
                                            : getContentType === "zip" ? <iframe width="1100" height="800" src={DMS_URL + `/${getUrl}`} ></iframe>
                                                : getContentType === "scorm" ? <iframe width="1100" height="800" src={DMS_URL + `/${getUrl}`} ></iframe>
                                                    : getContentType === "youtube" ? <ReactPlayer url={getUrl} width="100%" height="800px" controls="true"
                                                        config={{
                                                            youtube: {
                                                                playerVars: { showinfo: 1 }
                                                            }
                                                        }}
                                                    />

                                                        : <p>{t('no_content_available')}</p>
                        }
                    </Modal.Body>
                    {/* <DiscussionMain courseid={courseId} tenantid={tenantId} userid={userId} itemid={123} /> */}
                </Modal>
                {/* ContentView model code end here*/}


                {/* Certificate Modal */}

                <Modal centered show={getPaymentParameterView.show} onHide={() => PaymentParameterViewHide()} backdrop="static" className='custom-modal-style' size="lg" >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter" style={{ alignContent: "center" }}>
                            Enter payment Information to continue
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div style={{}}>
                            <label for="name">Additional Information:</label>
                            <Table class="table table-borderless">
                                <Row>
                                    <Col style={{ marginTop: "10px" }}>

                                        <input
                                            type="text"
                                            maxLength={100}
                                            className="form-control"
                                            id="merchant_param1"
                                            placeholder="message"
                                            name="merchant_param1"
                                            onChange={e => setPaymentInfo({ ...getPaymentInfo, merchant_param1: e.target.value })}
                                        />
                                        <span style={{ color: "red" }}>{getPaymentInfo.merchant_param1err}</span>
                                    </Col>
                                    <Col style={{ marginTop: "10px" }}>
                                        <input
                                            type="text"
                                            maxLength={100}
                                            className="form-control"
                                            id="merchant_param2"
                                            placeholder="message"
                                            name="merchant_param2"
                                            onChange={e => setPaymentInfo({ ...getPaymentInfo, merchant_param2: e.target.value })}
                                        />
                                        <span style={{ color: "red" }}>{getPaymentInfo.merchant_param2err}</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col style={{ marginTop: "10px" }}>
                                        <input
                                            type="text"
                                            maxLength={100}
                                            className="form-control"
                                            id="merchant_param3"
                                            placeholder="message"
                                            name="merchant_param3"
                                            onChange={e => setPaymentInfo({ ...getPaymentInfo, merchant_param3: e.target.value })}
                                        />
                                        <span style={{ color: "red" }}>{getPaymentInfo.merchant_param3err}</span>
                                    </Col>
                                    <Col style={{ marginTop: "10px" }}>
                                        <input
                                            type="text"
                                            maxLength={100}
                                            className="form-control"
                                            id="merchant_param4"
                                            placeholder="message"
                                            name="merchant_param4"
                                            onChange={e => setPaymentInfo({ ...getPaymentInfo, merchant_param4: e.target.value })}
                                        />
                                        <span style={{ color: "red" }}>{getPaymentInfo.merchant_param4err}</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col style={{ marginTop: "10px" }}>
                                        <input
                                            type="text"
                                            maxLength={100}
                                            className="form-control"
                                            id="merchant_param5"
                                            placeholder="message"
                                            name="merchant_param5"
                                            onChange={e => setPaymentInfo({ ...getPaymentInfo, merchant_param5: e.target.value })}
                                        />
                                        <span style={{ color: "red" }}>{getPaymentInfo.merchant_param5err}</span>
                                    </Col>
                                    <Col style={{ marginTop: "10px" }}>
                                    </Col>
                                </Row>
                            </Table>
                            <br></br>
                            <label for="name">Promo code:</label>
                            <Table>
                                <Row>
                                    <Col style={{ marginTop: "10px" }}>
                                        <input
                                            type="text"
                                            maxLength={100}
                                            className="form-control"
                                            id="promo_code"
                                            placeholder="Add promo code"
                                            name="promo_code"
                                            onChange={e => setPaymentInfo({ ...getPaymentInfo, promo_code: e.target.value })}
                                        />
                                    </Col>
                                    <Col style={{ marginTop: "10px" }}>
                                    </Col>
                                </Row>
                            </Table>
                        </div>
                    </Modal.Body>
                    <Modal.Footer style={{ justifyContent: "center" }}>
                        <Button variant="primary" style={{ background: 'linear-gradient(90deg, #007bc7 0%, #003994 100%)', border: '0px' }}
                            onMouseOut={changeBackgroundOut} onMouseOver={changeBackgroundOver}
                            onClick={() => { paymentForCourse(courseId, tenantId) }}
                        >
                            Proceed with payment
                        </Button>
                        <Button variant="secondary" onClick={() => PaymentParameterViewHide()} style={{ border: '0px' }}>
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>

            </Styles>

            {/* Footer 2 */}
            <FooterTwo />

        </div >
    )
}

export default CourseDetails
