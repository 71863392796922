import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Frontend_UI } from '../../../services/service';

// import Frontend_UI from ;

const MediaContentView = ({ cmsUrl }) => {

  const [htmlContent, setHtmlContent] = useState(null);

  useEffect(() => {

    axios.get(cmsUrl)
      .then(response => {
        setHtmlContent(response.data);
       
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, [cmsUrl]);

  return (
    // <iframe
    // 	title="Embedded HTML Page for Video Streaming"
    // 	srcDoc={`<base href=${Frontend_UI} /><div>${
    // 		htmlContent === undefined || htmlContent ===null ? 
    // 		<div style={{}}>Loading...</div> 
    // 		: htmlContent
    // 	}</div>`}
    // 	width="100%"
    // 	height="800"
    // />
    <iframe
    	title="Embedded HTML Page for Video Streaming"
    	srcDoc={`<base href=${Frontend_UI} /><div style="position: relative; height: 800px;">${htmlContent === undefined || htmlContent === null ?
    		'<div class="loader" style=" position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);">Loading...</div>'
    		: htmlContent
    		}</div>`}
    	width="100%"
    	height="800"
    	style={{position: "relative"}}
    	/>
    // <iframe
    //   title="Embedded HTML Page for Video Streaming"
    //   srcDoc={`<base href=${Frontend_UI} /><div style="position: relative; height: 800px;">${htmlContent === undefined || htmlContent === null ?
    //       '<div class="loader" style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); width: 100px; height: 100px; border: 4px solid #f3f3f3; border-top: 4px solid #3498db; border-radius: 50%; animation: spin 2s linear infinite;"></div>'
    //       : htmlContent
    //     }</div>`}
    //   width="100%"
    //   height="800"
    //   style={{ position: "relative" }}
    // />


  )
}

export default MediaContentView;

