import Keycloak from "keycloak-js";
import axios from 'axios';
import HttpService from "./HttpService";
import { useEffect, useState } from "react";
import service from "./service";
import UserActionLogin from "../pages/account/UserActionLogin";
import Modal from 'react-bootstrap/Modal';
import React from 'react';
const _kc = new Keycloak('/eprabodhini.json');


const data = {
  browser: null,
  os: null,
  resolution: null,
  ip: null
};

const getCurrentBrowser = () => {
  let browserName;

  if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf("OPR")) !== -1) {
    browserName = "Opera";
  } else if (navigator.userAgent.indexOf("Edg") !== -1) {
    browserName = "Edge";
  } else if (navigator.userAgent.indexOf("Chrome") !== -1) {
    browserName = "Chrome";
  } else if (navigator.userAgent.indexOf("Safari") !== -1) {
    browserName = "Safari";
  } else if (navigator.userAgent.indexOf("Firefox") !== -1) {
    browserName = "Firefox";
  } else if (
    navigator.userAgent.indexOf("MSIE") !== -1 || !!document.documentMode === true) {
   
    browserName = "IE";
  } else {
    browserName = "Unknown";
  }

  data.browser = browserName;
 
};

const getResolution = () => {
  const height = window.screen.availHeight;
  const width = window.screen.availWidth;

  const res = `${height} x ${width}`;

  data.resolution = res;
};



const getCurrentOS = () => {
  var Name = "Not known";
  if (navigator.appVersion.indexOf("Win") !== -1) Name = "Windows OS";
  if (navigator.appVersion.indexOf("Mac") !== -1) Name = "MacOS";
  if (navigator.appVersion.indexOf("X11") !== -1) Name = "UNIX OS";
  if (navigator.appVersion.indexOf("Linux") !== -1) Name = "Linux OS";

  data.os = Name;
};


const getData = async () => {
  getCurrentBrowser();
  getCurrentOS();
  getResolution();
  service.saveUserActionDetails(data.browser, data.os, data.resolution)

};

const FireFoxModal = () => data.browser;


/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = (onAuthenticatedCallback) => {
  _kc.init({
    onLoad: 'check-sso',
    silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
    responseMode: 'fragment',
    flow: 'standard',
    pkceMethod: 'S256',
  })

    .then((authenticated) => {
      // if (authenticated) {
      if (authenticated === true) {
        let emailid = UserService.getUserid();
        let ipaddress = "0.0.0.0";
        let action = "Login";
        let os = "";
        //let ip = UserAction.ip;
        let height = window.innerHeight;
        let width = window.innerWidth;
        let resolution = height + ' * ' + width;
        //UserService.getData();
        let browserName = navigator.appCodeName;
        let result = "true";
        let siteid = 1;
        let sessionId = UserService.getSessionId();
        var params = new URLSearchParams();

        
      }
      getData();
      onAuthenticatedCallback();
      
    })
};

const doLogin = _kc.login;

const doLogout = _kc.logout;

const getToken = () => _kc.token;

const isLoggedIn = () => !!_kc.token;

const updateToken = (successCallback) =>
  _kc.updateToken(5)
    .then(successCallback)
    .catch(doLogin);

const getUsername = () => _kc.tokenParsed?.name;
const getUserid = () => _kc.tokenParsed?.sub;
const getEmail = () => _kc.tokenParsed?.email;
const getSessionId = () => _kc.tokenParsed?.session_state;
const instRole = () => _kc.tokenParsed?.resource_access.reactclient.roles[0];
const learnerRole = () => _kc.tokenParsed?.realm_access.roles[3];


const generateToken = () => {
  if (UserService.getToken() !== undefined) {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${UserService.getToken()}`;
  }
}

const hasRole = (roles) => roles.some((role) => _kc.hasRealmRole(role) || _kc.hasResourceRole(role));

/* According to condition check your role has role and resource role */
// const hasRole = (roles) => roles.some((role) => _kc.hasRealmRole(role) || _kc.hasResourceRole(role));


const UserService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  FireFoxModal,
  getToken,
  updateToken,
  getUsername,
  getUserid,
  hasRole,
  getEmail,
  getSessionId,
  instRole,
  learnerRole,
  generateToken
};

export default UserService;
