import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { Button, Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap'
import instructorService from '../../../services/instructorService';
import swal from 'sweetalert';
import "./UpdateCourseDuration.css";
import DataTable from 'react-data-table-component';
import { useHistory } from 'react-router-dom';
import { addDays } from 'date-fns';


const UpdateCourseDuration = ({ courseId }) => {

  const history = useHistory();

  const [courseDuration, setCourseDuration] = useState(0);
  const [courseDurationData, setCourseDurationData] = useState([]);
  const [courseData, setCourseData] = useState({});
  const [newEndDate, setNewEndDate] = useState(null);


  const [errors, setErrors] = useState({});

  const onDurationChange = (duration) => {

    setCourseDuration(duration);
    setNewEndDate(formatDate(moment(formatDate(courseData?.closingDate)).add(duration, 'days')));
  }

  const onDurationBlur = () => {
    const errors = {};
    if (isNaN(courseDuration)) {
      errors.duration = "Duration must be a number";
    } else if (courseDuration < 0) {
      errors.duration = "Duration must be number and cannot be negative";
    }

    setErrors(errors);
  }

  useEffect(() => {
    fetchCourseDurationData();
    fetchCourseData();
  }, [courseId])


  const fetchCourseDurationData = () => {
    instructorService.getCourseDuartionUpdateData(courseId)
      .then((res) => {
        if (res.status == 200) {
          setCourseDurationData(res.data);
        }
      })
      .catch((err) => {
        
        swal("Error", err, "error");
      })
  }

  const fetchCourseData = () => {
    instructorService.getCourseById(courseId)
      .then((res) => {
        setCourseData(res.data);
      })
      .catch((err) => {
        swal("Error", "Server Error please try again later", "error")
      })
  }

  const convertTimestampToDate = (timestamp) => {
    return moment(timestamp).format('MMMM Do YYYY, h:mm:ss a');
  };

  const durationColumns = [
    {
      name: "S.No",
      selector: (row, index) => index + 1,
      wrap: true,
      width: '150px',
    },
    {
      name: "Duration(Days)",
      selector: row => row.duartion,
      wrap: true,
      sortable: true,
      width: '150px',
    },
    {
      name: "Updated On",
      selector: row => convertTimestampToDate(row.updatedOn),
      sortable: true,
      wrap: true,
      width: '250px',
      // right: true
    }
  ];

  const onSubmitButtonClick = () => {

    if (Object.keys(errors).length !== 0) {
      swal("Warning", "Please Check the duration again", "warning")
    } else {
      instructorService.updateCourseDuration(courseId, courseDuration)
        .then((res) => {
          if (res.status === 200) {
            swal("Success", "Successfully Updated the Schedules", "success")
            fetchCourseDurationData();
            fetchCourseData();
          } else {

          }
        })
        .catch((err) => {
         
          swal("Error", err, "error");
        })
      setNewEndDate(null);
    }
    setCourseDuration(0);
  }

  const handleGoBack = () => {
    history.push({
      pathname: '/ViewCourses',
      state: { showCourseEditModal: true, courseId }
    });
  };

  const formatDate = (date) => {
    return moment(date).format('YYYY-MM-DDTHH:mm');
  };

  

  return (
    <div className='main-course-duration-update-page'>
      <Row>
        <Col lg={5}>
          <div className='course-duration-update-left'>
            <div className='input-area-duration'>
              <label className='label-duration'>Course Duration Extend(in Days):
                <OverlayTrigger
                  overlay={
                    <Tooltip id="tooltip-disabled">
                      Enter the Duration by which you want to extend the Course Duration (in Days)
                    </Tooltip>}
                >
                  <i class="fa fa-info-circle" style={{ fontSize: '18px' }}></i>
                </OverlayTrigger>
              </label>
              <input
                type="number"
                className="courseDuration-form"
                value={courseDuration}
                onChange={(e) => onDurationChange(e.target.value)}
                onBlur={() => onDurationBlur()}
                placeholder={"Enter the Course Commencement Date"}
                min={0}
              />
              {errors?.duration && <span className="error-message">{errors?.duration}</span>}
            </div>
            <div className='submit-button-area'>
              <div className='d-flex justify-content-end'>
                <Button
                  size='lg'
                  className='submit-button mr-2 btn-success' // Add margin-right for spacing
                  onClick={onSubmitButtonClick}
                  disabled={courseDuration < 1}
                >
                  Submit
                </Button>
                <Button
                  size='lg'
                  className='goback-button btn-info'
                  onClick={handleGoBack}
                >
                  Go Back
                </Button>
              </div>
            </div>
            <div className='input-area-enddate'>
              <label className='label-publish'>Current Course End Date:
                <OverlayTrigger
                  overlay={
                    <Tooltip id="tooltip-enddate">
                      Current Course End Date for reference
                    </Tooltip>}
                >
                  <i class="fa fa-info-circle" style={{ fontSize: '18px' }}></i>
                </OverlayTrigger>
              </label>
              <input
                type="datetime-local"
                className="enddate-form"
                value={formatDate(courseData?.closingDate)}
                disabled
              />
            </div>
            <div className='input-area-newdate'>
              <label className='label-publish'>Updated Course End Date:
                <OverlayTrigger
                  overlay={
                    <Tooltip id="tooltip-newdate">
                      The updated End Date for reference, changes when duration value changes
                    </Tooltip>}
                >
                  <i class="fa fa-info-circle" style={{ fontSize: '18px' }}></i>
                </OverlayTrigger>
              </label>
              <input
                type="datetime-local"
                className="newdate-form"
                value={formatDate(newEndDate)}
                disabled
              />
            </div>
          </div>
        </Col>
        <Col lg={7}>
          <DataTable
            columns={durationColumns}
            data={courseDurationData}
            pagination
          />
        </Col>
      </Row>
    </div>
  )
}

export default UpdateCourseDuration