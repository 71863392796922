import React, { useEffect, useState } from 'react';
import service, {USER_API} from '../../services/service';
import UserService from '../../services/UserService';
import ViewPdf from '../../pages/instructor/ViewPdf';

export const TokenImage = ({ path }) => {
    const token = UserService.getToken();
  const [imgSrc, setImgSrc] = useState('');

  useEffect(() => {
    const fetchImage = async () => {
      const url = `${USER_API}getprofilepicforadminverification/${path}`;
      try {
        const response = await fetch(url, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const blob = await response.blob();
        const imgUrl = URL.createObjectURL(blob);
        setImgSrc(imgUrl);
      } catch (error) {
      }
    };

    fetchImage();
  }, [path, token]);

  return (
    <img
      src={imgSrc}
      alt="Profile"
      style={{ width: '42px', height: '42px', borderRadius: '100%', boxShadow: "5px 10px 20px 1px rgba(0, 0, 0, 0.253)" }}
    />
  );
};


export const TokenImageinModel = ({ path }) => {
  const token = UserService.getToken();
const [imgSrc, setImgSrc] = useState('');

useEffect(() => {
  const fetchImage = async () => {
    const url = `${USER_API}getprofilepicforadminverification/${path}`;
    try {
      const response = await fetch(url, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const blob = await response.blob();
      const imgUrl = URL.createObjectURL(blob);
      setImgSrc(imgUrl);
    } catch (error) {
    }
  };

  fetchImage();
}, [path, token]);

return (
  <img
    src={imgSrc}
    alt="Profile"
    style={{ width: '120px' }}
  />
);
};

export const TokenIDImageinModel = ({ path }) => {
  const token = UserService.getToken();
const [imgSrc, setImgSrc] = useState('');
const [pdfSrc, setPdfSrc] = useState('');
const [getContentType, setContentType] = useState();

useEffect(() => {
  const fetchImage = async () => {
    const url = `${USER_API}getidforadminverification/${path}`;
    try {
      const response = await fetch(url, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      const contentType = response.headers.get('Content-Type');
      setContentType(contentType);


      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      if(contentType == "image/jpeg" || contentType == "image/jpg" || contentType == "image/png"){

        const blob = await response.blob();
        const imgUrl = URL.createObjectURL(blob);
        setImgSrc(imgUrl);

      } else if (contentType === "application/pdf") {
        const blob = await response.blob();
        const pdfUrl = URL.createObjectURL(blob);
        setPdfSrc(pdfUrl);
      }

     
    } catch (error) {
    }
  };

  fetchImage();
}, [path, token]);

return (
  <>
    { (getContentType == "image/jpeg" || getContentType == "image/jpg" || getContentType == "image/png") &&  (<img
    src={imgSrc}
    alt="Profile"
    style={{ width: '600px' }}
  />)}
  {getContentType === "application/pdf" && (
     <ViewPdf
     pdfUrl={pdfSrc}
   />
       
      )}
  </>
 
);
};


export const TokenCertificateImageinModel = ({ path }) => {
  const token = UserService.getToken();
const [imgSrc, setImgSrc] = useState('');
const [pdfSrc, setPdfSrc] = useState('');
const [getContentType, setContentType] = useState();

useEffect(() => {
  const fetchImage = async () => {
    const url = `${USER_API}getcertificateforadminverification/${path}`;
    try {
      const response = await fetch(url, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      const contentType = response.headers.get('Content-Type');
      setContentType(contentType);


      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      if(contentType == "image/jpeg" || contentType == "image/jpg" || contentType == "image/png"){

        const blob = await response.blob();
        const imgUrl = URL.createObjectURL(blob);
        setImgSrc(imgUrl);

      } else if (contentType === "application/pdf") {
        const blob = await response.blob();
        const pdfUrl = URL.createObjectURL(blob);
        setPdfSrc(pdfUrl);
      }
    } catch (error) {
    }
  };

  fetchImage();
}, [path, token]);

return (
  <>
  { (getContentType == "image/jpeg" || getContentType == "image/jpg" || getContentType == "image/png") &&  (<img
  src={imgSrc}
  alt="Profile"
  style={{ width: '600px' }}
/>)}
{getContentType === "application/pdf" && (
   <ViewPdf
   pdfUrl={pdfSrc}
 />
    
    )}
</>
);
};

