import styled from "styled-components";
import { colors } from "../../../components/common/element/elements";


export const Styles = styled.div`
.container-fluid .row{
    padding : 1.5rem;
    margin-right: -36px;
    margin-left: 20px;
    .card {
        width: 15rem;
        height:16rem;
        border-radius: 5px;
        &:hover {
            box-shadow: 5px 10px 20px 1px rgba(0, 0, 0, 0.253) !important;
        }
    
        .card-body {
           padding: 2rem 0 !important;
        }
    
        .card-text {
            font-size: .9rem;
            //padding: 0.4rem 1.9rem;
        }
    
        .overflow {
            overflow: hidden;
        }
        
        .card-img-top {
            transform: scale(1);
            transition: transform 0.5s ease;
            //height : 230px;
            height : 160px;
            border : 1px solid white;
            border-radius: 5px;
            box-shadow: 5px 10px 20px 1px rgba(0, 0, 0, 0.253) !important;   
        }
        
        .card-img-top:hover {
            transform: scale(1.8);
        }
    }
}
   
  
`;