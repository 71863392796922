import React, { useEffect, useState } from 'react';
import instructorService from '../../../services/instructorService';
import moment from 'moment';
import "./UpdateCourseSchedule.css";
import { Button, Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import swal from 'sweetalert';
import { useHistory } from 'react-router-dom';

const UpdateCourseSchedule = ({ courseId }) => {

    // Get the current date and time
    const currentDateTime = moment().format('YYYY-MM-DDTHH:mm');
    const history = useHistory();

    const [commenceDate, setCommenceDate] = useState(new Date());
    const [enrollStartDate, setEnrollStartDate] = useState(new Date());
    const [enrollEndDate, setEnrollEndDate] = useState(new Date());
    const [publishDate, setPublishDate] = useState(new Date());

    const [courseData, setCourseData] = useState({});

    const [errors, setErrors] = useState({});

    useEffect(() => {
        fetchCourseData();
    }, [courseId]);

    useEffect(() => {
        // setDates();
    }, [courseData]);

    const fetchCourseData = () => {
        instructorService.getCourseById(courseId)
            .then((res) => {
                setCourseData(res.data);
                setCommenceDate(formatDate(res.data.commenceDate));
                setEnrollStartDate(formatDate(res.data.enrollStartDate));
                setEnrollEndDate(formatDate(res.data.enrollEndDate));
                setPublishDate(formatDate(res.data.publishDate));
            })
            .catch((err) => {
                swal("Error", "Server Error please try again later", "error")
            })
    }

    const commenceDateChangeHandler = (value) => {
        setCommenceDate(moment(value).toISOString());
    };

    const enrollSdateChangeHandler = (value) => {
        setEnrollStartDate(moment(value).toISOString());
    };

    const enrollEdateChangeHandler = (value) => {
        setEnrollEndDate(moment(value).toISOString());
    };

    const formatDate = (date) => {
        return moment(date).format('YYYY-MM-DDTHH:mm');
    };

    const commenceDateBlurHandler = () => {
        validateDates(publishDate, commenceDate, enrollStartDate, enrollEndDate);

    }

    const enrollStartDateBlurHandler = () => {
        validateDates(publishDate, commenceDate, enrollStartDate, enrollEndDate);
    };

    const enrollEndDateBlurHandler = () => {
        validateDates(publishDate, commenceDate, enrollStartDate, enrollEndDate);
    }

    // Function to validate dates
    const validateDates = (publishDate, commenceDate, enrollStartDate, enrollEndDate) => {
        const errors = {};

        if (moment(commenceDate).isBefore(publishDate)) {
            errors.commenceDate = "Commence date must be after publish date.";
        }

        if (moment(enrollStartDate).isBefore(publishDate)) {
            errors.enrollStartDate = "Enroll start date must be after publish date.";
        }

        if (moment(enrollStartDate).isAfter(commenceDate)) {
            errors.enrollStartDate = "Enroll start date must be before commencement date.";
        }

        if (moment(enrollEndDate).isBefore(enrollStartDate)) {
            errors.enrollEndDate = "Enroll end date must be after enroll start date.";
        }

        if (moment(enrollEndDate).isBefore(publishDate)) {
            errors.enrollEndDate = "Enroll end date must be after publish date.";
        }

        // Additional validations can be added if needed

        setErrors(errors);
    };

    const onSubmitButtonClick = () => {

        if (Object.keys(errors).length !== 0) {
            swal("Warning", "Please Check the dates again", "warning")
        } else {
            instructorService.updateCourseSchedule(courseId, commenceDate, enrollStartDate, enrollEndDate)
                .then((res) => {
                    if (res.status === 200) {
                        swal("Success", "Successfully Updated the Schedules", "success")
                    }
                })
                .catch((err) => {
                  
                    swal("Error", err, "error");
                })
        }
    }

    const handleGoBack = () => {
        history.push({
            pathname: '/ViewCourses',
            state: { showCourseEditModal: true, courseId }
        });
    };

    


    return (
        <div className='main-course-schedule-update-page'>
            {/* <h3 className='course-schedule-update-heading'>
                Update Course Schedule
            </h3> */}
            {/* <p
                className='course-schedule-update-heading'
                style={{ textAlign: 'center' }}
            >
                Select the dates and Submit
            </p> */}
            <Row>
                <Col lg={6}>
                    <div className='input-area-publish'>
                        <label className='label-publish'>Course Publish Date:
                            <OverlayTrigger
                                overlay={
                                    <Tooltip id="tooltip-disabled">
                                        Disabled, Cannot be changed
                                    </Tooltip>}
                            >
                                <i class="fa fa-info-circle" style={{ fontSize: '18px' }}></i>
                            </OverlayTrigger>
                        </label>
                        <input
                            type="datetime-local"
                            className="publish-form"
                            value={formatDate(publishDate)}
                            disabled
                        />
                    </div>
                </Col>
                <Col lg={6}>
                    <div className='input-area-commence'>
                        <label className='label-commence'>Course Commencement Date:
                            <OverlayTrigger
                                overlay={
                                    <Tooltip id="tooltip-disabled">
                                        Enter the new Course Commencement Date, must be greater than the Publish Date
                                    </Tooltip>}
                            >
                                <i class="fa fa-info-circle" style={{ fontSize: '18px' }}></i>
                            </OverlayTrigger>
                        </label>
                        <input
                            type="datetime-local"
                            className="commenceDate-form"
                            value={formatDate(commenceDate)}
                            onChange={(e) => commenceDateChangeHandler(e.target.value)}
                            onBlur={() => commenceDateBlurHandler()}
                            placeholder={"Enter the Course Commencement Date"}
                            min={publishDate}
                        />
                        {errors?.commenceDate && <span className="error-message">{errors?.commenceDate}</span>}
                    </div>
                </Col>
                <Col lg={6}>
                    <div className='input-area-enrollStart'>
                        <label className='label-enrollStart'>Enrollment Start Date:
                            <OverlayTrigger
                                overlay={
                                    <Tooltip id="tooltip-disabled">
                                        Enter the new Enrollment Start Date, must be greater than the Publish Date and less than the Commencemnt Date
                                    </Tooltip>}
                            >
                                <i class="fa fa-info-circle" style={{ fontSize: '18px' }}></i>
                            </OverlayTrigger>
                        </label>
                        <input
                            type="datetime-local"
                            className="enrollStartDate-form"
                            value={formatDate(enrollStartDate)}
                            onChange={(e) => enrollSdateChangeHandler(e.target.value)}
                            onBlur={() => enrollStartDateBlurHandler()}
                            placeholder={"Enter the Course Enrollment Start Date"}
                            min={publishDate}
                        />
                        {errors?.enrollStartDate && <span className="error-message">{errors?.enrollStartDate}</span>}
                    </div>
                </Col>
                <Col lg={6}>
                    <div className='input-area-enrollEnd'>
                        <label className='label-enrollEnd'>Enrollment End Date:
                            <OverlayTrigger
                                overlay={
                                    <Tooltip id="tooltip-disabled">
                                        Enter the new Enrollment End Date, must be greater than the Enrollment Start Date and Current Date                           </Tooltip>}
                            >
                                <i class="fa fa-info-circle" style={{ fontSize: '18px' }}></i>
                            </OverlayTrigger>
                        </label>
                        <input
                            type="datetime-local"
                            className="enrollEndDate-form"
                            value={formatDate(enrollEndDate)}
                            onChange={(e) => enrollEdateChangeHandler(e.target.value)}
                            onBlur={() => enrollEndDateBlurHandler()}
                            placeholder={"Enter the Course Enrollment End Date"}
                            min={publishDate}
                        />
                        {errors?.enrollEndDate && <span className="error-message">{errors?.enrollEndDate}</span>}
                    </div>
                </Col>
            </Row>
            <div className='submit-button-area'>
                <div className='d-flex justify-content-end'>
                    <Button
                        size='lg'
                        className='submit-button mr-2 btn-success' // Add margin-right for spacing
                        onClick={onSubmitButtonClick}
                    >
                        Submit
                    </Button>
                    <Button
                        size='lg'
                        className='goback-button btn-info'
                        onClick={handleGoBack}
                    >
                        Go Back
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default UpdateCourseSchedule